import React, { useState, useEffect, useRef } from 'react';
import './index.less';
import { patientDetailsHeaderDict } from './patientDetailsDict';
import BasicInformation from './BasicInformation';
import GeneralCheckUp from './GeneralCheckUp';
import FirstContactInfo from './FirstContactInfo';
import FriendsRelation from './FriendsRelation';
import IntroducerList from './IntroducerList';
import { fetchData } from '../../middleware/fetchData';
import { fromJS } from 'immutable';
import { Spin, message } from 'antd';
import rowLeftIcon from '../../image/rowLeftIcon.png';
import rowRightIcon from '../../image/rowRightIcon.png';
import { isPc } from '../../utils/commonUtils';

// 患者信息
const PatientDetails = () => {
    const [currentPage, setCurrentPage] = useState(patientDetailsHeaderDict[0].key);
    const [loading, setLoading] = useState(false);
    const [patientInfo, setPatientInfo] = useState(fromJS({}));
    const isPcFlag = isPc();

    useEffect(() => {
        getPatientInfo();
    }, []);

    // 获取患者信息
    const getPatientInfo = () => {
        setLoading(true);
        fetchData(`/his-war/customer/selectBaseInfo`, {
            body: JSON.stringify({
                id: sessionStorage.patinenterId
            })
        }).then((res) => {
            if (res && res.data) {
                setLoading(false);
                setPatientInfo(fromJS(res.data || {}));
            } else {
                setLoading(false);
                message.info('获取基本信息出错了' + (res ? ':' + res.message : ''));
            }
        });
    };

    // 切换头部导航
    const handleChangeHeader = (e, value) => {
        setCurrentPage(value);
        e.target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    };

    // 滚动到两边
    const handleScrollHeader = (e, direction) => {
        e.stopPropagation();
        if (direction === 'left') {
            document.getElementsByClassName('header-item')[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        } else {
            document.getElementsByClassName('header-item')[4].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    const renderHeader = () => {
        return <div className="header-box">
            {isPcFlag && <img className='arrow-icon left-arrow' src={rowLeftIcon} onClick={e => handleScrollHeader(e, 'left')} alt=""/>}
            {patientDetailsHeaderDict.map(item => {
                return <div
                    className={currentPage !== item.key ? 'header-item' : 'header-item current-item'}
                    key={item.key}
                    onClick={(e) => handleChangeHeader(e, item.key)}
                >
                    {item.value}
                </div>;
            })}
            {isPcFlag && <img className='arrow-icon right-arrow' src={rowRightIcon} onClick={e => handleScrollHeader(e, 'right')}  alt=""/>}
        </div>;
    };

    const renderContent = () => {
        switch (currentPage) {
            case 'BASIC_INFORMATION':
                return loading ? <Spin className='patient-details-spin' /> : <BasicInformation patientInfo={patientInfo} />;
            case 'GENERAL_CHECK_UP':
                return loading ? <Spin className='patient-details-spin' /> : <GeneralCheckUp patientInfo={patientInfo} />;
            case 'FIRST_CONTACT_INFO':
                return loading ? <Spin className='patient-details-spin' /> : <FirstContactInfo patientInfo={patientInfo} />;
            case 'FRIENDS_RELATION':
                return loading ? <Spin className='patient-details-spin' /> : <FriendsRelation patientInfo={patientInfo} getPatientInfo={getPatientInfo} />;
            case 'INTRODUCER_LIST':
                return <IntroducerList patientInfo={patientInfo} />;
            default:
                return <React.Fragment />;
        }
    };

    return (
        <div className="patient-details-box">
            {renderHeader()}
            {renderContent()}
        </div>
    );
};


export default PatientDetails;