import React, { useState } from 'react';
import './bindResult.less';
import successImg from '../../image/success.svg';
import errorImg from '../../image/error.svg';
import warningImg from '../../image/warning.svg';

const BindResult = props => {

    const flag = window.location.pathname.split('/').pop();
    const [bindStatus, setBingStatus] = useState(flag === '1' ? 'success' : flag === '201' ? 'warning' : 'error');

    const statusObj = {
        warning: {
            info: '您已绑定过手机号，无需重复绑定',
            img: warningImg
        },
        success: {
            info: '绑定成功，请联系工作人员进行复诊',
            img: successImg
        },
        error: {
            info: '绑定失败，请重新扫码',
            img: errorImg
        }
    };

    return (
        <div className="bindResult">
            <img alt="" src={statusObj[bindStatus].img} className="resultStatusImg" />
            <p className="resultStatusInfo">{statusObj[bindStatus].info}</p>
        </div>
    );
};

export default BindResult;