import moment from 'moment';

/**
 * 后台给csv格式的数据下载
 * @param {Object} encoded 后端 获取到的数据
 * @param {String} name 文件名称
 */
export function exportCsvFile(encoded, name) {
    const dataBlob = new Blob([`\ufeff${encoded}`], { type: 'text/plain;charset=utf-8' }); //返回的格式
    const url = window.URL.createObjectURL(dataBlob);
    const linkA = document.createElement('a');
    linkA.href = url;
    linkA.download = `${name || moment().format('YYYY-MM-DD HH:mm:ss')}.csv`;
    linkA.click();
    window.URL.revokeObjectURL(url);
}

/**
 * 获取url 问号传参的值
 * @param {String} url
 * */
export function getRouterUrlState(url) {
    if (!url) return {};
    let newText = url.split('?')[1];
    if (!newText) return {};
    let newText1 = newText.split('&');
    let obj = {};
    (newText1 || []).map(item => {
        let list = item.split('=');
        obj[list[0] || ''] = list[1] || '';
    });
    return obj || {};
}

/*
 * 扁平省市区数据 树结构化
 */
export function setRegion(list = []) {
    if (!list || !Array.isArray(list)) return [];
    let cityObj = {};
    let areaObj = {};
    let provinceList = list.filter(v => {
        v.label = v.name;
        v.value = v.id;
        if (v.level === 2) {
            if (cityObj[v.pid]) {
                cityObj[v.pid].push(v);
            } else {
                cityObj[v.pid] = [v];
            }
        } else if (v.level === 3) {
            if (areaObj[v.pid]) {
                areaObj[v.pid].push(v);
            } else {
                areaObj[v.pid] = [v];
            }
        }
        return v.level === 1;
    });
    Object.keys(cityObj).map(item => {
        cityObj[item] = cityObj[item].map(t => {
            t.children = areaObj[t.code];
            return t;
        });
    });
    provinceList = provinceList.map(item => {
        item.children = cityObj[item.code];
        return item;
    });
    return provinceList;
}

/*
 * 根据id 获取省市区名称
 * list 全部省市区
 * isList 要找到的
 */
export function getAddressName(list, idList) {
    if (!list || !idList || idList.length == 0) return [];
    let newList = [];
    let province = [];
    let city = [];
    let area = [];
    if (idList[0]) {
        province = list.filter(item => item.id === idList[0])[0] || {};
        newList.push(province.label);
    }
    if (idList[1]) {
        city = (province.children || []).filter(item => item.id === idList[1])[0] || {};
        newList.push(city.label);
    }
    if (idList[2]) {
        area = (city.children || []).filter(item => item.id === idList[2])[0] || {};
        newList.push(area.label);
    }
    return newList;
}

/*
 * 根据名称获取省市区id
 * list 全部省市区
 * nameList
 */
export function getAddressIdList(list, nameList) {
    if (!list || !nameList || nameList.length == 0) return [];
    let newList = [];
    let province = [];
    let city = [];
    let area = [];
    if (nameList[0]) {
        province = list.filter(item => item.name === nameList[0])[0] || {};
        console.log(province);
        newList.push(province.value || -1);
    }
    if (nameList[1]) {
        city = (province.children || []).filter(item => item.name === nameList[1])[0] || {};
        newList.push(city.value || -1);
    }
    if (nameList[2]) {
        area = (city.children || []).filter(item => item.name === nameList[2])[0] || {};
        newList.push(area.value || -1);
    }
    return newList;
}
/**
 * 根据生日计算年龄
 * params: strAge
 * */
export function getAge(strAge) {
    let tempAge = strAge || '';
    if (typeof tempAge === 'string' && tempAge.search('-') !== -1 && tempAge.split("-").length === 3) {
        var birArr = tempAge.split("-");
        var birYear = birArr[0];
        var birMonth = birArr[1];
        var birDay = birArr[2];

        d = new Date();
        var nowYear = d.getFullYear();
        var nowMonth = d.getMonth() + 1; //记得加1
        var nowDay = d.getDate();
        var returnAge;

        if (birArr == null) {
            return false
        };
        var d = new Date(birYear, birMonth - 1, birDay);
        if (d.getFullYear() == birYear && (d.getMonth() + 1) == birMonth && d.getDate() == birDay) {
            if (nowYear == birYear) {
                returnAge = 0; //
            } else {
                var ageDiff = nowYear - birYear; //
                if (ageDiff > 0) {
                    if (nowMonth == birMonth) {
                        var dayDiff = nowDay - birDay; //
                        if (dayDiff < 0) {
                            returnAge = ageDiff - 1;
                        } else {
                            returnAge = ageDiff;
                        }
                    } else {
                        var monthDiff = nowMonth - birMonth; //
                        if (monthDiff < 0) {
                            returnAge = ageDiff - 1;
                        } else {
                            returnAge = ageDiff;
                        }
                    }
                } else {
                    return  "出生日期晚于今天，数据有误"; //返回-1 表示出生日期输入错误 晚于今天
                }
            }
            return returnAge;
        }
    } else {
        return ;
    }
}
export function stringDate(date) {
    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    var day = new Date().getDate();
    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    var dateString =  year + "-" + month + "-" + day;
    return dateString
}

/*
    * 参数说明：
    * number：要格式化的数字
    * decimals：保留几位小数
    * dec_point：小数点符号
    * thousands_sep：千分位符号
    * */
export function numberFormat(number, decimals, dec_point, thousands_sep) {
    decimals = decimals || 2;
    dec_point = dec_point || '.';
    thousands_sep = thousands_sep || ',';
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.ceil(n * k) / k;
        };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

/**
 * 减法
 * @param arg1
 * @param arg2
 * @returns {string}
 */
export function floatSubtraction(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    n = r1 >= r2 ? r1 : r2;
    return parseFloat(((arg1 * m - arg2 * m) / m).toFixed(n));
}