import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import reducers from './reducers';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import Root from './Root';
import VConsole from 'vconsole';
import { isPc } from './utils/commonUtils';

let store = createStore(reducers, {}, applyMiddleware(thunk));

if (isPc()) {
    window.pc = true;
}
// if (process.env.Text !== 'prod') {
//     const vConsole = new VConsole();
// }

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <Root store={store} />
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
