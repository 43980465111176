import React, { useState, useEffect } from 'react';
import { fetchData } from '../../middleware/fetchData';
import { Spin, message } from 'antd';
import './introducerList.less';
import { fromJS } from 'immutable';
import noData from '../../image/noData.png';
import moment from 'moment';

// 患者信息-介绍人列表
const IntroducerList = props => {
    const { patientInfo } = props;
    const customer = patientInfo.get('customer') || fromJS({});
    const [loading, setLoading] = useState(false);
    const [infoData, setInfoData] = useState(fromJS([]));
    const [pagination, setPagination] = useState({
        pageNum: 1,
        pageSize: 10,
        total: 0
    });

    useEffect(() => {
        getIntroducerListInfo();
    }, []);

    // 获取介绍人列表
    const getIntroducerListInfo = (pageNum = 1) => {
        setLoading(true);
        fetchData(`/his-war/customer/searchCustUser`, {
            body: JSON.stringify({
                pageNum: pageNum,
                pageSize: pagination.pageSize,
                condition: {
                    id: sessionStorage.patinenterId
                }
            })
        }).then((res) => {
            if (res && res.data) {
                setLoading(false);
                const pageInfo = res.data.pageInfo || {};
                setPagination({
                    pageNum: pageInfo.pageNum || 0,
                    pageSize: pageInfo.pageSize || 10,
                    total: pageInfo.total || 0,
                });
                let dataList = pageInfo.list || [];
                if (pageInfo.pageNum > 1) {
                    setInfoData(infoData.concat(fromJS(dataList || [])));
                } else {
                    setInfoData(fromJS(dataList || []));
                }
            } else {
                setLoading(false);
                message.info('获取介绍人列表出错了' + (res ? ':' + res.message : ''));
            }
        });
    };

    // 获取更多
    const handleGetMore = () => {
        getIntroducerListInfo(pagination.pageNum + 1);
    };

    // 介绍人列表
    const renderIntroducerList = introducer => {
        return <div className='basic-info'>
            <div className="bottom-container">
                <div className="info-item">
                    <div className="info-label">姓名</div>
                    <div className="info-value">{introducer.get('name')}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">病例号</div>
                    <div className="info-value">{introducer.get('recordId')}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">性别</div>
                    <div className="info-value">{introducer.getIn(['sex', 'itemValue'])}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">手机</div>
                    <div className="info-value">{introducer.get('mobile')}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">初诊时间</div>
                    <div className="info-value">{introducer.get('firstClinicTime') ? moment(introducer.get('firstClinicTime')).format('YYYY-MM-DD') : ''}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">初诊医生</div>
                    <div className="info-value">{introducer.getIn(['doctor', 'name'])}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">创建机构</div>
                    <div className="info-value">{introducer.get('createOrgName')}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">消费总金额</div>
                    <div className="info-value red-font">{introducer.get('referredYysr')}</div>
                </div>
            </div>
        </div>;
    };

    return loading ? <Spin className='patient-details-spin' /> : <div className="introducer-list-box">
        <div className="top-title">
            <div className="info-title">介绍人</div>
            {['员工推荐', '朋友介绍'].includes(
                customer.getIn(['sourceCode', 'itemCode'])
            ) && <div className="right-info">引荐人：
                {customer.get('emplName')}
            </div>}
        </div>
        {infoData.size
            ? infoData.map(item => {
                return renderIntroducerList(item);
            })
            : <div className="noData"> <img src={noData} alt="" /></div>
        }
        {pagination.pageSize * pagination.pageNum < pagination.total
        && <div className='get-more-btn' onClick={handleGetMore}>查看更多</div>}
    </div>;
};


export default IntroducerList;