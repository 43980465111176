import React, { useEffect, useState, useRef } from 'react';
import { InputItem } from 'antd-mobile';
import { useDispatch } from 'react-redux';
import { Button, Spin, message } from 'antd';
import { fetchData } from '../../middleware/fetchData';
import { telReg } from '../../utils/commonUtils';
import boySexImg from '../../image/boy.svg';
import girlSexImg from '../../image/girl.svg';
import bingdingImg from '../../image/link.svg';
import defaultHeadImg from '../../image/defaultHeadImg.png';
import { sendVerifyCode, checkVerifyCode, queryCustomer, bindCustomer } from '../../actions/infoBindAction';

import './index.less';
import moment from "moment";

const InfoBinding = props => {

    const { history } = props;
    const dispatch = useDispatch();
    const phoneRef = useRef();
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [patientInfoList, setPatientInfoList] = useState('');
    const [getCodeBtnDisabled, setGetCodeBtnDisabled] = useState(true);
    const [searchBtnDisable, setSearchBtnDisable] = useState(true);
    const checkPhone = useRef(null);

    useEffect(() => {
        document.title = '名片查询';
        const search = window.location.search;
        const newSearch = unescape(search);
        const searchArr = newSearch.slice(1, newSearch.length).split('&');
        // 获取门诊名称
        if (!sessionStorage.orgName) {
            const orgName = (searchArr.find(item => item.includes('orgName')) || '').replace('orgName=', '') || '';
            sessionStorage.orgName = decodeURI(decodeURI(orgName));
        }
        if (sessionStorage.authFlag === 'Y' && sessionStorage.unionId) {
            queryUnionIdBind();
            return;
        }
        if (sessionStorage.authFlag !== 'Y') {
            if (search.includes('code=')) {
                const code = (searchArr.find(item => item.includes('code')) || '').replace('code=', '');
                getUserId(code);
            } else {
                queryAppInfo();
            }
        }
    }, []);

    useEffect(() => {
        let isPageHide = false;
        window.addEventListener('pageshow', function () {
            if (isPageHide) {
                window.location.reload();
            }
        });
        window.addEventListener('pagehide', function () {
            isPageHide = true;
        });
    }, []);

    useEffect(() => {
        if (userInfo.phone && (/^1([38][0-9]|4[579]|5[^4]|6[2567]|7[^49]|9[189])\d{8}$/.test(userInfo.phone || '')) && userInfo.verifyCode) {
            setSearchBtnDisable(false);
        } else {
            setSearchBtnDisable(true);
        }
        if (userInfo.phone && (/^1([38][0-9]|4[579]|5[^4]|6[2567]|7[^49]|9[189])\d{8}$/.test(userInfo.phone || ''))) {
            if (!!checkPhone.current && getCodeBtnDisabled) {
                return;
            }
            setGetCodeBtnDisabled(false);
        } else {
            setGetCodeBtnDisabled(true);
        }
    }, [userInfo.phone, userInfo.verifyCode]);

    // 获取APPID
    const queryAppInfo = () => {
        fetchData('/his-war/wxApp/queryAppInfo ').then((res) => {
            if (!res) return;
            let flag = res.flag;
            if (typeof flag !== 'undefined' && flag === 1) {
                const appId = res.data;
                let uri = encodeURIComponent(window.location.origin + window.location.pathname);
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
            }
        });
    };

    // 根据code获取当前登陆企业微信的userId
    const getUserId = (code) => {
        sessionStorage.code = code;
        fetchData(`/his-war/wxUser/queryWxUserInfo?code=${code}`).then((res) => {
            if (!res) return;
            let { flag, message } = res;
            if (typeof flag !== 'undefined' && flag === 1) {
                sessionStorage.authFlag = 'Y';
                sessionStorage.unionId = res.data.unionId;
                sessionStorage.headImgUrl = res.data.headImgUrl;
                sessionStorage.nickname = res.data.nickname;
                // 查询当前unionId是否绑定过
                queryUnionIdBind();
            } else {
                message.error('出错了：' + message);
            }
        });
    };

    const queryUnionIdBind = () => {
        fetchData('/his-war/weChat/cp/checkCustomerBindUnionId', { body: JSON.stringify({ unionId: sessionStorage.unionId }) }).then((res) => {
            if (!res) return;
            let { flag, message } = res;
            if (typeof flag !== 'undefined' && flag === 201) {
                window.history.replaceState({}, '', window.location.origin + '/infoBinding/bindResult/201');
                window.location.reload();
            } else if (flag === 1) {
                // 未绑定
            } else {
                message.error('出错了：' + message);
            }
        });
    };


    // 手机号输入
    const handlePhonechange = value => {
        setUserInfo({ ...userInfo, phone: value });
        if (value.length === 11) {
            if (!value || !(/^1([38][0-9]|4[579]|5[^4]|6[2567]|7[^49]|9[189])\d{8}$/.test(value || ''))) {
                message.error('请输入正确的手机号！');
                return;
            }
        }
    };

    // 监听获取验证码按钮的点击操作
    useEffect(() => {
        let t = 60;
        let btn = document.getElementById('extra');
        //设置监听事件，按下按钮后禁用按钮
        btn.addEventListener('click', function () {
            setGetCodeBtnDisabled(true);
            if (!getCodeBtnDisabled) return;
            dispatch(sendVerifyCode(phoneRef.current.state.value || ''));
            //添加定时器，时间间隔为一秒
            checkPhone.current = setInterval(function () {
                if (t === 0) {
                    clearInterval(checkPhone.current);
                    checkPhone.current = null
                    setGetCodeBtnDisabled(false);
                    btn.innerHTML = '获取验证码';
                    t = 60;
                } else {
                    btn.innerHTML = t + '秒后重新获取';
                    t--;
                }
            }, 1000);
        });
    }, []);

    // 验证码输入
    const handleCodeChange = (value) => {
        setUserInfo({ ...userInfo, verifyCode: value });
    };

    // 查询患者
    const handleQueryPatientInfo = () => {
        // 验证验证码是否正确
        dispatch(checkVerifyCode(userInfo.phone, userInfo.verifyCode, () => {
            setLoading(true);
            // 查询患者
            dispatch(queryCustomer({ phone: userInfo.phone, unionId: sessionStorage.unionId }, (value) => {
                setLoading(false);
                if (value === 'fail') {
                    window.history.replaceState({}, '', window.location.origin + '/infoBinding/bindResult/201');
                    window.location.reload();
                    return;
                }
                setPatientInfoList(value);
            }));
        }));
    };

    // 绑定微信
    const handleBindCustomer = (value) => {
        let { id } = value;
        dispatch(bindCustomer({ unionId: sessionStorage.unionId, phone: userInfo.phone, customerId: id, wechatNickname: sessionStorage.nickname, wechatHeadImg: sessionStorage.headImgUrl }, (flag) => {
            console.log(flag);
            window.history.replaceState({}, '', window.location.origin + `/infoBinding/bindResult/${flag}`);
            window.location.reload();
        }));
    };

    return (
        <div className="infoBinding">
            <p className="orgName">{sessionStorage.orgName || ''}</p>
            <p className="info">复诊信息查询绑定</p>
            <InputItem
                ref={phoneRef}
                placeholder="请输入手机号"
                maxLength="11"
                onChange={(value) => handlePhonechange(value)}
            />
            <InputItem
                maxLength={6}
                className="getCodeInout"
                extra={<Button type="link" disabled={getCodeBtnDisabled} className={(userInfo.phone && !getCodeBtnDisabled) ? 'extra canGetCode' : 'extra'} id="extra">获取验证码</Button>}
                placeholder="请输入验证码"
                onChange={(value) => handleCodeChange(value)}
            />
            <Button onClick={handleQueryPatientInfo} type="primary" disabled={searchBtnDisable} shape="round" className="searchBtn" >去查询</Button>
            <Spin spinning={loading}>
                <div className="bingResultList">
                    {patientInfoList.length > 0 && <p className="info">查询结果:</p>}
                    {
                        patientInfoList.length > 0 ? patientInfoList.map((item, index) => {
                            return (
                                <div className="bingResultList-item" key={index}>
                                    <div className="left">
                                        <div className="userImg">
                                            <img alt="" src={item.avatarUrl || item.wechatHeadImg || defaultHeadImg} />
                                            <img alt="" src={item.sex && (item.sex.itemCode === 'SEX_M' ? boySexImg : girlSexImg)} className="sexImg" />
                                        </div>
                                        <div>
                                            {/* 责任医生： */}
                                            <p className="userName">{item.dutyDoctor ? (item.dutyDoctor.name || '-') : '-'}</p>
                                            <p className="recordNum">生日：{item.birthday ? moment(item.birthday).format('YYYY-MM-DD') : '-'}</p>
                                        </div>
                                    </div>
                                    <span className="bindBtn" onClick={() => handleBindCustomer(item)}>绑定</span>
                                    {/* <img onClick={() => handleBindCustomer(item)} alt="" src={bingdingImg} className="bindImg" /> */}
                                </div>
                            );
                        }) : <p className="noListData">{patientInfoList && '未查询到首诊信息，请联系工作人员'}</p>
                    }
                </div>
            </Spin>
        </div>
    );
};

export default InfoBinding;