import { message } from "antd";
import { fetchData } from "../../middleware/fetchData";

// action type
export const CHANGE_VISIT_LIST_LOADING = 'CHANGE_VISIT_LIST_LOADING';
export const CHANGE_VISIT_LIST = 'CHANGE_VISIT_LIST';
export const CHANGE_VISIT_RESULT_CONTENT = 'CHANGE_VISIT_RESULT_CONTENT';
export const CHANGE_VISIT_LIST_OPEN_STATUS = 'CHANGE_VISIT_LIST_OPEN_STATUS';
export const CHANGE_VISIT_VISIT_RESULT_CONTENT_LOADING = 'CHANGE_VISIT_VISIT_RESULT_CONTENT_LOADING';
export const RESET_VISIT_LIST = 'RESET_VISIT_LIST';

// action creator
export const changeLoading = flag => {
    return {
        type: CHANGE_VISIT_LIST_LOADING,
        data: flag
    };
};

// 清空列表数据
export const resetVisitList = () => {
    return {
        type: RESET_VISIT_LIST
    };
};

export const changeResultDetailLoading = flag => {
    return {
        type: CHANGE_VISIT_VISIT_RESULT_CONTENT_LOADING,
        data: flag
    };
};

// 获取回访列表数据
export const getVisitList = (current, pageSize, callback) => dispatch => {
    let params = {
        pageNum: current,
        pageSize,
        condition: { customerId: sessionStorage.patinenterId }
    };
    fetchData(`/his-war/customerFollowUp/followUpList`, {
        body: JSON.stringify(params)
    }).then(res => {
        if (!res) return;
        dispatch(changeLoading(false));
        const { flag, message: msg = '', data } = res;
        if (!data) return;
        let { pageInfo = {} } = data || {};
        if (typeof flag !== 'undefined' && flag === 1) {
            const { list = [], pageSize = 10, pageNum = 1, total = 0 } = pageInfo;
            callback && callback(pageNum, pageSize, total);
            dispatch({
                type: CHANGE_VISIT_LIST,
                data: list || []
            });
        } else {
            message.error(`获取列表失败，失败原因：${msg}`);
            return;
        }
    });
};

// 取消回访计划
export const updateStatus = (customerFollowUpId, statusCode) => dispatch => {
    fetchData(`/his-war/customerFollowUp/updateStatus`, {
        body: JSON.stringify({ customerFollowUpId, statusCode, customerId: sessionStorage.patinenterId })
    }).then(res => {
        if (!res) return;
        const { flag, message: msg } = res;
        if (typeof flag !== 'undefined' && flag === 1) {
            dispatch(changeLoading(true));
        } else {
            message.error(`取消回访计划失败，失败原因：${msg}`);
            return;
        }
    });
};

// 完成回访
export const finishFollowUp = (params, callback) => dispatch => {
    fetchData(`/his-war/customerFollowUp/finishFollowUp`, {
        body: JSON.stringify({ ...params, customerId: sessionStorage.patinenterId } || {})
    }).then(res => {
        if (!res) return;
        const { flag, message: msg } = res;
        if (typeof flag !== 'undefined' && flag === 1) {
            callback && callback();
            message.success('回访保存成功！');
        } else {
            message.error(`完成回访失败，失败原因：${msg}`);
            return;
        }
    });
};

// 新增回访计划
export const insertFollowUp = (params, callback) => dispatch => {
    fetchData(`/his-war/customerFollowUp/insertFollowUp`, {
        body: JSON.stringify({ ...params, customerId: sessionStorage.patinenterId })
    }).then(res => {
        if (!res) return;
        const { flag, message: msg } = res;
        if (typeof flag !== 'undefined' && flag === 1) {
            callback && callback();
        } else {
            message.error(`新增回访计划失败，失败原因：${msg}`);
            return;
        }
    });
};

// 查询回访回显
export const queryCustomerFollowUpById = (customerFollowUpId) => dispatch => {
    console.log(customerFollowUpId)
    fetchData(`/his-war/customerFollowUp/queryCustomerFollowUpById`, {
        body: JSON.stringify({ customerFollowUpId })
    }).then(res => {
        if (!res) return;
        const { flag, message: msg, data } = res;
        dispatch(changeResultDetailLoading(false));
        if (typeof flag !== 'undefined' && flag === 1) {
            dispatch({
                type: CHANGE_VISIT_RESULT_CONTENT,
                data: data
            });
        } else {
            message.error(`查询回访失败，失败原因：${msg}`);
            return;
        }
    });
};

