import { fromJS } from 'immutable';
import { GET_INIT_DATA, CUSTOMER_DETAIL_FOLLOWED_ADD_INIT_SUCC } from '../../actions/patientInfo';

export default (
    state = fromJS({
        dictMap: {},
        contentList: [],
        resultList: [],
        userList: [],
        organizations:[]
    }),
    action
) => {
    const { type, data } = action;
    switch (type) {
        case GET_INIT_DATA:
            let newState = state;
            newState = state.set('dictMap', data.get('dictMap'));
            newState = newState.set("organizations", fromJS(data.get('clinicList')));
            return newState;
        case CUSTOMER_DETAIL_FOLLOWED_ADD_INIT_SUCC:
            let initState = state
            // initState = initState.set("loading", false)
            initState = initState.set(
                "contentList",
                fromJS(action.data.customerFollowUpInfoResDTO.contentList)
            )
            initState = initState.set(
                "resultList",
                fromJS(action.data.customerFollowUpInfoResDTO.resultList)
            )
            initState = initState.set(
                "userList",
                fromJS(action.data.customerFollowUpInfoResDTO.userList)
            )
            return initState
        default:
            return state;
    }
};