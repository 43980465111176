// 患者详情顶部导航字段
export const patientDetailsHeaderDict = [
    {
        key: 'BASIC_INFORMATION',
        value: '基本信息'
    },
    {
        key: 'GENERAL_CHECK_UP',
        value: '全身检查'
    },
    {
        key: 'FIRST_CONTACT_INFO',
        value: '首诊信息'
    },
    {
        key: 'FRIENDS_RELATION',
        value: '亲友关系'
    },
    {
        key: 'INTRODUCER_LIST',
        value: '介绍人列表'
    }
];