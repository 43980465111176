import React, {useEffect, useState} from 'react';
import './orderDetail.less';
import OrderInitSum from './OrderInitSum';
import {fetchData} from "../../middleware/fetchData";
import {fromJS} from "immutable";
import {message, Spin, Empty} from "antd";
import { numberFormat } from '../../utils/util';
import noDataImg from "../../image/noData.png";

// 患者信息-收费-详情
const OrderDetail = props => {
    const {
        location: {
            state: {initSum}
        },
        match: {
            params: {id}
        },
        history
    } = props;
    const [detailData, setDetailData] = useState(fromJS({}));
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(fromJS({}));

    useEffect(() => {
        fetchData('/his-war/pay/init.do', {
            body: JSON.stringify({customerId: sessionStorage.patinenterId})
        }).then(res => {
            if(!res){
                return;
            }
            if(res.flag === 1 && res.data){
                setCustomer(fromJS(res.data.customer || {}));
            }
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchData('/his-war/pay/orderDetail', {
            body: JSON.stringify({id})
        }).then(res => {
            setLoading(false);
            if(!res){
                return;
            }
            if(res.flag === 1 && res.data){
                setDetailData(fromJS(res.data));
            } else {
                message.info(res.message);
            }
        });
    }, []);



    return (
        <div className='order-detail-wrap'>
            <OrderInitSum initSum={initSum} />
            <p className='divider'></p>
            <Spin spinning={loading}>
                {detailData.size ? (
                    <div className='order-detail'>
                        <div className='item'>
                            <h3>基础信息</h3>
                            <p className='label'><span>患者</span>{customer.get('name')}</p>
                            <p className='label'><span>会员卡</span>{customer.get('memberCard')}</p>
                            <p className='label'><span>可用余额</span>{numberFormat(customer.get('balanceAmount'))}元</p>
                            {!!detailData.get('oweAmount') && <p className='label'><span>欠费金额</span>{numberFormat(detailData.get('oweAmount'))}元</p>}
                            {!!detailData.get('refundAmount') && <p className='label'><span>退费金额</span>{numberFormat(detailData.get('refundAmount'))}元</p>}
                            <p className='label'><span>医生</span>{detailData.getIn(['doctor', 'name'])}</p>
                            <p className='label'><span>护士</span>{detailData.getIn(['nurse', 'name'])}</p>
                            <p className='label'><span>咨询</span>{detailData.getIn(['sale', 'name'])}</p>
                        </div>
                        <div className='item'>
                            <h3 style={{paddingBottom: 0}}>费用明细</h3>
                            <p className='tips'>折扣数值：0代表免费；0.5代表5折；1代表不打折</p>
                            {detailData.get('details') && detailData.get('details').size ? (
                                <div className='detail-list'>
                                    {detailData.get('details').map(detailItem => {
                                        return (
                                            <div key={detailItem.get('id')} className='detail-item'>
                                                <p className='pattern-name'>{detailItem.getIn(['pattern', 'name'])} <span>套</span></p>
                                                <p>
                                                    <span>单价/套餐价：¥{numberFormat(detailItem.get('itemPrice'))}</span>
                                                    <span>数量：{detailItem.get('itemCount')}</span>
                                                </p>
                                                <p>
                                                    <span>折前金额：¥{numberFormat(detailItem.get('itemSumPrice'))}</span>
                                                    <span>折后金额：¥{numberFormat(detailItem.get('totalItemAmount'))}</span>
                                                </p>
                                                <span className='discount'>{detailItem.get('itemDiscount')}折</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : <Empty />}
                        </div>
                        <div className='item'>
                            <h3>费用总计</h3>
                            <p className='label'><span>专家费</span>{numberFormat(detailData.get('expertFee'))}元</p>
                            <p className='label'><span>明细费用</span>{numberFormat(detailData.get('totalItemAmount'))}元</p>
                            <p className='label'><span>应收合计</span>{numberFormat(detailData.get('totalPrice'))}元</p>
                            <p className='label'><span>实收</span>{numberFormat(detailData.get('paidAmount'))}元</p>
                        </div>
                    </div>
                ) : (
                    <img className='empty-img' src={noDataImg} alt="" />
                )}
            </Spin>
            <div className='btn'><button onClick={() => history.go(-1)}>返回</button></div>
        </div>
    );
};

export default OrderDetail;