import React from 'react';
import './orderInitSum.less';
import { numberFormat, floatSubtraction } from '../../utils/util';

// 患者信息-收费-金额总和
const OrderInitSum = ({initSum}) => {
    return (
        <div className='order-init-sum'>
            <div className='order-init-sum-box'>
                <div>
                    <div className='item line'>
                        <p>本金余额</p>
                        <p>¥{numberFormat(initSum.deductAmount)}</p>
                    </div>
                    <div className='item'>
                        <p>可用余额</p>
                        <p>¥{numberFormat(floatSubtraction(initSum.deductAmount, initSum.freezeAmount))}</p>
                    </div>
                    <div className='item'>
                        <p>冻结余额</p>
                        <p>¥{numberFormat(initSum.freezeAmount)}</p>
                    </div>
                </div>
                <div>
                    <div className='item'>
                        <p>累计充值</p>
                        <p>¥{numberFormat(initSum.preSumAmount)}</p>
                    </div>
                    <div className='item'>
                        <p>实收总额</p>
                        <p>¥{numberFormat(initSum.preRemainAmount)}</p>
                    </div>
                    <div className='item'>
                        <p>欠费总额</p>
                        <p>¥{numberFormat(initSum.sumOweAmount)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderInitSum;