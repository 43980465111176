import React, { useState, useEffect, useRef } from 'react';
import { message, Spin } from 'antd';
import moment from 'moment';
import { fromJS } from 'immutable';
import { fetchData } from '../../middleware/fetchData';
import noDataImg from '../../image/noData.png';
import './index.less';

const SeeDoctorRecord = () => {
    const [recordList, setRecordList] = useState(fromJS([]));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData('/his-war/reservation/searchResvInfoByCustomerId', {
            body: JSON.stringify({ customerId: sessionStorage.patinenterId })
        }).then((res) => {
            if (res && res.data) {
                setRecordList(fromJS(res.data || []));
                setLoading(false);
            } else {
                message.info('查询就诊记录出错了');
                setLoading(false);
            }
        });
    }, []);

    const reservationTableStatus = (reservation) => {
        if (!reservation) {
            return '';
        }
        let status = reservation.statusCode.itemCode;
        let time = null;
        if (status === 'REGISTERED') {
            time = moment(reservation.regStartTime);
            let days = moment().subtract(time, 'days');
            let minutes = moment().subtract(time, 'minutes');
            if (days === 0 && minutes > 0) {
                return <span className={'reservation_status_NOTIFY'}>{`等待${minutes}分钟`}</span>;
            }
        } else if (
            status === 'UNCONFIRMED' ||
            status === 'CONFIRMED' ||
            status === 'CANCEL'
        ) {
            time = moment(reservation.resvStartTime);
            let days = moment().subtract(time, 'days');
            let minutes = moment().subtract(time, 'minutes');
            if (days === 0 && minutes > 0) {
                return <span className={'reservation_status_NOTIFY'}>{`迟到${minutes}分钟`}</span>;
            }
        }
        return (
            <span className={`reservation_status_${status}`}>{reservation.statusCode.itemValue}</span>
        );
    };

    return (
        <div className="seeDoctor-content">
            <Spin spinning={loading} className="loading-tip">
                {recordList.size ? recordList.map(reservation => {
                    return (
                        <div className="seeDoctor-record-item">
                            <div className="time-row">
                                <div className="left-content">
                                    <span className="time">
                                        {moment(reservation.get('resvDay')).format('YYYY-MM-DD')}
                                    </span>
                                    <span className="time">{`${moment(reservation.get('resvStartTime')).format('HH:mm')}-${moment(reservation.get('resvEndTime')).format('HH:mm')}`}</span>
                                    {reservation.getIn(['typeCode', 'itemCode']) ===
                                        'FIRST_DIAGNOSIS' && (
                                        <span className="record-type" style={{background: '#397CFF'}}>初</span>
                                    )}
                                    {reservation.getIn(['typeCode', 'itemCode']) ===
                                        'RETURN_DIAGNOSIS' && (
                                        <span className="record-type" style={{background: '#00B769'}}>复</span>
                                    )}
                                </div>
                                <div className="right-content">
                                    {reservationTableStatus(reservation.toJS())}
                                </div>
                            </div>
                            <div className="record-message">
                                <div className="outpatient-name">{reservation.getIn(['resvOrg', 'name'])}</div>
                                <div className="message-content">
                                    <div className="message-item" >
                                        <div className="item-title">创建人</div>
                                        <div className="item-value">
                                            {!!reservation.getIn(['createUser', 'name'])
                                                ? reservation.getIn(['createUser', 'name'])
                                                : '无'}
                                        </div>
                                    </div>
                                    <div className="message-item" >
                                        <div className="item-title">挂号医生</div>
                                        <div className="item-value">
                                            {['CANCEL', 'UNCONFIRMED', 'CONFIRMED', 'NO_RESV'].includes(
                                                reservation.getIn(['statusCode', 'itemCode'])
                                            )
                                                ? '无'
                                                : !!reservation.getIn(['resvDoctor', 'name'])
                                                    ? reservation.getIn(['resvDoctor', 'name'])
                                                    : '无'}
                                        </div>
                                    </div>
                                    <div className="message-item" >
                                        <div className="item-title">挂号咨询师</div>
                                        <div className="item-value">
                                            {['CANCEL', 'UNCONFIRMED', 'CONFIRMED', 'NO_RESV'].includes(
                                                reservation.getIn(['statusCode', 'itemCode'])
                                            )
                                                ? '无'
                                                : !!reservation.getIn(['resvConsult', 'name'])
                                                    ? reservation.getIn(['resvConsult', 'name'])
                                                    : '无'}
                                        </div>
                                    </div>
                                    <div className="message-item" >
                                        <div className="item-title">接诊医生</div>
                                        <div className="item-value">
                                            {!!reservation.getIn(['firstDoctor', 'name'])
                                                ? reservation.getIn(['firstDoctor', 'name'])
                                                : '无'}
                                        </div>
                                    </div>
                                    <div className="message-item" >
                                        <div className="item-title">接诊咨询师</div>
                                        <div className="item-value">
                                            {!!reservation.getIn(['firstConsult', 'name'])
                                                ? reservation.getIn(['firstConsult', 'name'])
                                                : '无'}
                                        </div>
                                    </div>
                                    <div className="message-item" >
                                        <div className="item-title">预约项目</div>
                                        <div className="item-value">
                                            {!!reservation.getIn(['reservationProduct', 'name'])
                                                ? reservation.getIn(['reservationProduct', 'name'])
                                                : '无'}
                                        </div>
                                    </div>
                                    <div className="message-item" >
                                        <div className="item-title">预约医生</div>
                                        <div className="item-value">
                                            {!!reservation.getIn(['resvDoctor', 'name'])
                                                ? reservation.getIn(['resvDoctor', 'name'])
                                                : '暂无'}
                                        </div>
                                    </div>
                                    {reservation.getIn(['statusCode', 'itemCode']) === 'CANCEL' && (
                                        <div className="message-item" style={{flex: '100%'}}>
                                            <div className="item-title">取消原因</div>
                                            <div className="item-value">
                                                {reservation.get('cancelReason') || ''}
                                            </div>
                                        </div>
                                    )}
                                    <div className="message-item" style={{flex: '100%'}}>
                                        <div className="item-title">备注</div>
                                        <div className="item-value">
                                            {reservation.get('remark') || ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {reservation.getIn(['statusCode', 'itemCode']) !== 'UNCONFIRMED' &&
                                reservation.getIn(['statusCode', 'itemCode']) !== 'CONFIRMED' &&
                                reservation.getIn(['statusCode', 'itemCode']) !== 'CANCEL' &&
                                reservation.getIn(['statusCode', 'itemCode']) !== 'NO_RESV' && (
                                <div className="spot-diagnosis">洁牙点诊：{reservation.get('isDiagnosis') === 'Y' ? '是' : '否'}</div>
                            )}
                        </div>
                    );
                }) : <div className="no-data-content"><img src={noDataImg} alt="" /></div>}
            </Spin>
        </div>
    );
};

export default SeeDoctorRecord;