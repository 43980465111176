import React from 'react';
import './basicInfomation.less';
import { fromJS } from 'immutable';

// 患者信息-首诊信息
const FirstContactInfo = props => {
    const { patientInfo } = props;
    const firstVisit = patientInfo.get('firstVisit') || fromJS({});

    // 首诊信息
    const renderFirstContactInfo = () => {
        return <div className='basic-info'>
            <div className="info-title">首诊信息</div>
            <div className="info-item">
                <div className="info-label">主诉</div>
                <div className="info-value">
                    {
                        firstVisit.get('chief')
                            ? firstVisit.get('chief')
                            : ''
                    }
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">现病史</div>
                <div className="info-value">
                    {
                        firstVisit.get('history')
                            ? firstVisit.get('history')
                            : ''
                    }
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">既往史</div>
                <div className="info-value">
                    {
                        firstVisit.get('pastHistory')
                            ? firstVisit.get('pastHistory')
                            : ''
                    }
                </div>
            </div>
        </div>;
    };

    return <div className="basic-information-box">
        {renderFirstContactInfo()}
    </div>;
};


export default FirstContactInfo;