import React, { useState } from 'react';
import { message } from 'antd';
import './introducerList.less';
import { fromJS } from 'immutable';
import FriendsRelationEditModal from './FriendsRelationEditModal';
import addNewIcon from '../../image/addNewIcon.svg';
import deleteIcon from '../../image/deleteIcon.svg';
import editIcon from '../../image/editIcon.svg';
import { fetchData } from '../../middleware/fetchData';
import noData from '../../image/noData.png';

// 患者信息-亲友关系
const FriendsRelation = props => {
    const { patientInfo, getPatientInfo } = props;
    const relationships = patientInfo.get('relationships') || fromJS([]);
    const [currentEditItem, setCurrentEditItem] = useState(fromJS({})); // 当前编辑项
    const [isShowEditModal, setIsShowEditModal] = useState(false); // 编辑弹窗显隐

    // 新添加
    const addNewRelation = () => {
        if (currentEditItem && currentEditItem.size) {
            message.info('请先保存当前编辑项');
            return false;
        }
        setCurrentEditItem(fromJS({
            key: Date.now()
        }));
        setIsShowEditModal(true);
    };

    // 编辑
    const editNewRelation = item => {
        if (currentEditItem && currentEditItem.size) {
            message.info('请先保存当前编辑项');
            return false;
        }
        setCurrentEditItem(item);
        setIsShowEditModal(true);
    };

    // 删除
    const deleteRelation = item => {
        if (currentEditItem && currentEditItem.size) {
            message.info('请先保存当前编辑项');
            return false;
        }
        fetchData(`/his-war/customer/removeRelation`, {
            body: JSON.stringify({
                id: item.get('id')
            })
        }).then((res) => {
            if (res && res.flag === 1) {
                message.success('删除成功');
                getPatientInfo();
            } else {
                message.info('删除失败' + (res ? ':' + res.message : ''));
            }
        });
    };

    // 关闭编辑弹窗
    const handleCancelEditModal = () => {
        setIsShowEditModal(false);
        setCurrentEditItem(fromJS({}));
    };

    // 亲友关系
    const renderFriendsRelationList = () => {
        if (relationships.size) {
            return relationships.map(item => {
                return <div className="bottom-container" key={item.get('id') || item.get('key')}>
                    <div className="operation-box">
                        <img src={editIcon} alt="" onClick={() => editNewRelation(item)} />
                        <img src={deleteIcon} alt="" onClick={() => deleteRelation(item)} />
                    </div>
                    <div className="info-item">
                        <div className="info-label">关系类别</div>
                        <div className="info-value">{item.getIn(['typeCode', 'itemValue'])}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">关系人姓名</div>
                        <div className="info-value">{item.get('name')}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">性别</div>
                        <div className="info-value">{item.getIn(['sex', 'itemValue'])}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">手机</div>
                        <div className="info-value">{item.get('telphone')}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">会员卡类型</div>
                        <div className="info-value">{item.get('memberLevel')}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">会员卡账号</div>
                        <div className="info-value">{item.get('cardNum')}</div>
                    </div>
                    <div className="info-item">
                        <div className="info-label">备注</div>
                        <div className="info-value">{item.get('remark')}</div>
                    </div>
                </div>;
            });
        } else {
            return <div className="noData"> <img src={noData} alt="" /></div>;
        }
    };

    return <div className="introducer-list-box friends-relation-box">
        <div className='basic-info'>
            <div className="top-title">
                <div className="info-title">亲友关系</div>
            </div>
            {renderFriendsRelationList()}
        </div>
        <div className='add-relation-btn' onClick={addNewRelation}>
            <img src={addNewIcon} alt=""/>
            添加亲友关系
        </div>
        <FriendsRelationEditModal
            editItem={currentEditItem}
            visible={isShowEditModal}
            handleCancel={handleCancelEditModal}
            getRelationList={getPatientInfo}
        />
    </div>;
};


export default FriendsRelation;