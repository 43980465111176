import { message } from 'antd';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { fetchData } from '../middleware/fetchData';
/**
 * 判断是否是微信环境
 * @returns {boolean}
 */
export function getIsWxClient () {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    }
    return false;
}

/**
 * 判断是不是PC端
 * @returns {boolean}
 */
export function isPc () {
    if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        return false;
    }
    return true;
};

// 计算相差多少天数
export function getDayGap(date) {
    let currentDate = new Date(moment().format('YYYY-MM-DD'));
    let gap = Math.abs(currentDate.getTime() - new Date(moment(date).format('YYYY-MM-DD')));
    let dayGap = Math.floor(gap / 1000 / 60 / 60 / 24);
    return dayGap;
}

// 获取cookie
export function getCookie(name) {
    let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    let arr = document.cookie.match(reg);
    if (arr) {
        return arr[2];
    } else {
        return null;
    }
}
//手机号码验证
export function telReg(value) {
    if (!value) return false;
    const telRule = /^(((14[1,4]0)\d{7})|(((13[0-9])|(14[5,6,7,8,9])|(15[0,1,2,3,5,6,7,8,9])|(16[2,5,6,7])|(17[0,1,2,3,5,6,7,8])|(18[0-9])|(19[0,1,2,3,5,6,7,8,9]))\d{8}))$|((^(0[0-9]{2,3})-)([2-9][0-9]{6,7})$|^[2-9][0-9]{6,7}$)|(^((00852|0852|852)?)(-?)([69]\d{7})$)|(^((00853|0853|853)?)(-?)([6]\d{7})$)|(^((00886|0886|886)?)(-?)(09\d{8})$)/;
    if (!telRule.test(value)) {
        return false;
    } else {
        return true;
    }

}

// 检查文件是否符合上传要求
const beforeFileUpload = (file, limit, type) => {
    const suffix = file.name.slice(file.name.lastIndexOf('.') + 1);
    const isTYPE = type.includes(suffix);
    if (!isTYPE) {
        message.error(`请选择${type.join('/')}格式的文件！`);
        return isTYPE;
    }
    const isSIZE = file.size / 1024 / 1024 <= limit;
    if (!isSIZE) {
        message.error(`请选择小于${limit}M的文件！`);
        return isSIZE;
    }
    return isTYPE && isSIZE;
};

// 整合上传所需信息并发出请求
const prepareUploadFormData = (options) => {
    const formData = new FormData();
    formData.append('file', options.file);
    formData.append('data', options.data);
    return fetchData(options.action, { 
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
            options.onProgress && options.onProgress({ percent: (loaded / total) * 100 }, options.file);
        },
        body: formData
    });
};

// 处理上传错误
const handleUploadError = (response, onError) => {
    if (!response || response.code !== 200) {
        message.error('上传文件失败，请联系管理员！');
        onError && onError(response);
        return false;
    }
    return true;
};

// Upload自定义上传方法
export const customRequest = (options, limit = 5, type = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']) => {
    if (beforeFileUpload(options.file, limit, type)) {
        prepareUploadFormData(options).then(res => {
            if (handleUploadError(res)) {
                options.onSuccess(res, options.file);
            }
        });
    }
};

export const getUrlSearch = (search) => {
    let reg = /([^=&?]+)=([^=&?]+)/g,
        obj = {};
    search.replace(reg, function() {
        let key = arguments[1];
        let val = decodeURI(arguments[2]);
        obj[key] = val;
    });
    return obj;
};

// 获取节点信息
export const useClientRect = (responsive) => {
    const [rect, setRect] = useState(null);
    const ref = useCallback(node => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
            if (responsive) {
                window.addEventListener('resize', () => {
                    setRect(node.getBoundingClientRect());
                });
            }
        }
    }, []);
    return [rect, ref];
};