import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.less';
import './common.less';
import PatientInfomation from './containers/patientInformation';
import InfoBinding from './containers/infomationBinding';
import BindResult from './containers/infomationBinding/BindResult';

const history = createBrowserHistory({});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/patientInfomation" component={PatientInfomation} />
                    <Route path="/infoBinding/bindResult/:flag" component={BindResult} />
                    <Route path="/infoBinding" component={InfoBinding} />
                </Switch>
            </Router>
        );
    }
}

export default App;
