import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Accordion, SearchBar } from 'antd-mobile';
import { Route, Switch, Redirect } from 'react-router-dom';
import { message, Spin } from 'antd';
import { fetchData } from '../../middleware/fetchData';
import { getInitData, init } from '../../actions/patientInfo';
//
import addTagBtn from '../../image/addTagBtn.png';
import infoIcon from '../../image/infoIcon.svg';
import infoIconActive from '../../image/infoIconActive.svg';
import visitRecordIcon from '../../image/visitRecordIcon.svg';
import visitRecordIconActive from '../../image/visitRecordIconActive.svg';
import recordIcon from '../../image/recordIcon.svg';
import recordIconActive from '../../image/recordIconActive.svg';
import chargeIcon from '../../image/chargeIcon.svg';
import chargeIconActive from '../../image/chargeIconActive.svg';
import returnIcon from '../../image/returnIcon.svg';
import returnIconActive from '../../image/returnIconActive.svg';
import maleIcon from '../../image/maleIcon.svg';
import femaleIcon from '../../image/femaleIcon.svg';
import huiIcon from '../../image/huiIcon.png';
import qianIcon from '../../image/qianIcon.png';
import starIcon from '../../image/starIcon.png';
import sunIcon from '../../image/sunIcon.png';
import moonIcon from '../../image/moonIcon.png';
import headerImgDefault from '../../image/headerImgDefault.png';
import closeLabelModalBtn from '../../image/closeLabelModalBtn.png';
import pageErrorImg from '../../image/pageError.png';
//
import CommonDrawer from '../../components/common/CommonDrawer';
import ConsultingRecord from '../../components/ConsultingRecord';
import ReturnVisit from '../../components/ReturnVisit';
import AddReturnVisit from '../../components/ReturnVisit/AddReturnVisit';
import AddTemporaryReturnVisit from '../../components/ReturnVisit/AddTemporaryReturnVisit';
import SeeDoctorRecord from '../../components/SeeDoctorRecord';
import PatientDetails from '../../components/PatientDetails';
import OrderList from '../../components/Order/OrderList';
import OrderDetail from '../../components/Order/OrderDetail';
import './index.less';

const { Panel } = Accordion;

const NAV_CONFIG = [
    { title: '患者信息', icon: infoIcon, activeIcon: infoIconActive, link: '/patientInfomation/patientDetails' },
    { title: '就诊记录', icon: visitRecordIcon, activeIcon: visitRecordIconActive, link: '/patientInfomation/seeDoctorRecord' },
    { title: '咨询记录', icon: recordIcon, activeIcon: recordIconActive, link: '/patientInfomation/consultationRecord' },
    { title: '收费', icon: chargeIcon, activeIcon: chargeIconActive, link: '/patientInfomation/order' },
    { title: '回访', icon: returnIcon, activeIcon: returnIconActive, link: '/patientInfomation/returnVisit' },
];

// 患者信息
const PatientInformation = () => {
    const history = useHistory();
    const { location } = history;
    const dispatch = useDispatch();
    const tagBox = useRef();
    const tagChild = useRef();
    const saveRef = useRef(0);
    const [showMoreBtn, setShowMoreBtn] = useState(false);
    const [showTagNum, setShowTagNum] = useState(0);
    const [showTagWidth, setShowTagWidth] = useState(0);
    const [patientInfoData, setPatientInfoData] = useState({});
    const [patientTagData, setPatientTagData] = useState({});
    const [patientTags, setPatientTags] = useState([]); // 患者所有标签
    const [customerLableIds, setCustomerLableIds] = useState([]); // 患者系统标签
    const [wechatLableIds, setWechatLableIds] = useState([]); // 患者企微标签
    const [editLabelFlag, setEditLabelFlag] = useState(false);
    const [moreTagModalVisible, setMoreTagModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageError, setPageError] = useState(false);
    //
    const [renderTag, setRenderTag] = useState(false);
    const [canSaveTag, setCanSaveTag] = useState(true);
    // 患者列表 页面
    const [pageType, setPageType] = useState(''); // 当前页面类型 群/单聊
    const [pageStep, setPageStep] = useState('');
    const [patientListData, setPatientListData] = useState([]);
    const [searchPatientListData, setSearchPatientListData] = useState([]);
    const [searchTxt, setSearchTxt] = useState('');

    useEffect(() => {
        document.title = '患者信息';
        // 授权登录
        const search = window.location.search;
        const newSearch = unescape(search);
        const searchArr = newSearch.slice(1, newSearch.length).split('&');
        if (search.includes('code=')) {
            if (sessionStorage.loginFlag === 'Y') {
                wxConfig();
                return;
            }
            const appId = (searchArr.find(item => item.includes('appId')) || '').replace('appId=', '');
            const agentid = (searchArr.find(item => item.includes('agentid')) || '').replace('agentid=', '');
            appId && (localStorage.appId = appId);
            agentid && (localStorage.agentid = agentid);
            const code = (searchArr.find(item => item.includes('code')) || '').replace('code=', '');
            getUserId(code);
        } else {
            const appId = (searchArr.find(item => item.includes('appId')) || '').replace('appId=', '');
            const agentid = (searchArr.find(item => item.includes('agentid')) || '').replace('agentid=', '');
            appId && (localStorage.appId = appId);
            agentid && (localStorage.agentid = agentid);
            let uri = encodeURIComponent(window.location.origin + window.location.pathname + window.location.search);
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        }
    }, []);

    // 根据code获取当前登陆企业微信
    const getUserId = (code) => {
        fetchData('/his-war/wechat/weChatLogin', {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            data: `openCode=${code}`
        }).then(() => {
            sessionStorage.loginFlag = 'Y';
            wxConfig();
        });
    };

    const wxConfig = () => {
        let agentParams = {
            appId: localStorage.appId,
            agentid: localStorage.agentid,
            url: window.location.href,
            busType: 'agentConfig'
        };
        getJsapiTicket(agentParams, (agentData) => {
            // 通过agentConfig注入应用的权限
            window.wx.agentConfig({
                corpid: localStorage.appId,
                agentid: localStorage.agentid,
                timestamp: agentData.timestamp,
                nonceStr: agentData.nonceStr,
                signature: agentData.signature,
                jsApiList: ['getCurExternalContact', 'getContext', 'getCurExternalChat'],
                success: function () {
                    // 判断进入页面的入口，需要从外部联系人的profile或聊天工具栏进入页面才能获取，否则会报错
                    window.wx.invoke('getContext', {}, function (res) {
                        if (res.err_msg === 'getContext:ok') {
                            // 从单聊会话的工具栏进入
                            if (res.entry === 'single_chat_tools') {
                                // 获取当前外部联系人userid
                                window.wx.invoke('getCurExternalContact', {
                                }, function (res) {
                                    setPageType('single');
                                    setPageStep('infoStep');
                                    getPatientId(res.userId);
                                });
                            } else if (res.entry === 'group_chat_tools') {
                                // 从群聊会话的工具栏进入
                                window.wx.invoke('getCurExternalChat', {
                                }, function(res){
                                    if(res.err_msg === 'getCurExternalChat:ok'){
                                        setPageType('group');
                                        setPageStep('listStep');
                                        getPatientListData(res.chatId); //返回当前客户群的群聊ID
                                    }else {
                                        //错误处理
                                        message.info('获取当前客户群群聊ID失败');
                                    }
                                });
                            }
                        }
                    });
                },
                fail: function (res) {
                    if (res.errMsg.indexOf('function not exist') > -1) {
                        alert('版本过低请升级');
                    }
                }
            });
        });
    };

    const getJsapiTicket = (params, callback) => {
        fetchData('/his-war/weChat/cp/getJsapiTicket', { body: JSON.stringify(params) }).then((res) => {
            if (res && res.data) {
                callback(res.data);
            }
        });
    };

    // 查询患者id
    const getPatientId = (userId) => {
        fetchData('/his-war/weChat/cp/queryWechatCustomerId', { body: JSON.stringify({ externalUserId: userId }) }).then((res) => {
            if (res && res.data) {
                sessionStorage.patinenterId = res.data;
                // 初始化全局字典数据
                dispatch(getInitData());
                dispatch(init({ customerId: res.data }));
                getPatientInfoData();
                getPatientTagData();
            } else {
                message.info(res.message);
                setPageError(true);
                setLoading(false);
            }
        });
    }

    // 获取患者基本信息
    const getPatientInfoData = () => {
        fetchData('/his-war/customer/detail', {
            body: JSON.stringify({ id: sessionStorage.patinenterId })
        }).then((res) => {
            if (res && res.data) {
                setLoading(false);
                setPatientInfoData(res.data);
            } else {
                message.info('出错了：' + res.message);
            }
        });
    };

    // 获取标签及患者标签
    const getPatientTagData = () => {
        fetchData('/his-war/customerLabel/searchCustomerLabelByCustomer', {
            body: JSON.stringify({ customerId: sessionStorage.patinenterId })
        }).then((res) => {
            if (res && res.data) {
                setRenderTag(true);
                setPatientTagData(res.data);
                const { customerLabelList = [], wechatLabelList = [] } = res.data;
                setPatientTags([...customerLabelList, ...wechatLabelList]);
            } else {
                message.info('查询标签出错了：' + res.message);
            }
        });
    };

    // 动态计算标签宽度
    useEffect(() => {
        if (tagBox.current && tagBox.current.clientWidth && tagChild.current && tagChild.current.clientWidth) {
            let width = document.documentElement.getBoundingClientRect().width;
            let rem = Math.floor(((width * 100) / 375) / 100) * 100 || 100;
            console.log(tagChild.current.clientWidth, 'tagChild.current.clientWidth', tagBox.current.clientWidth - 0.5 * rem, 'tagBox.current.clientWidth - 0.5 * rem');
            if (tagChild.current.clientWidth > tagBox.current.clientWidth - 0.5 * rem) {
                setShowMoreBtn(true);
                let addWidth = 0;
                let showTagNum = 0;
                let showTagWidth = 0;
                if (tagChild.current.children && tagChild.current.children.length) {
                    [...tagChild.current.children].forEach(item => {
                        addWidth += Math.ceil(item.clientWidth + 0.06 * rem);
                        if (addWidth <= tagBox.current.clientWidth - 0.5 * rem) {
                            showTagNum += 1;
                            showTagWidth = addWidth;
                        }
                    });
                }
                setShowTagWidth(showTagWidth);
                setShowTagNum(showTagNum);
            } else {
                setShowMoreBtn(false);
                setShowTagNum(patientTags.length);
            }
        }
    }, [tagBox.current, patientTags.length]);


    const handleShowEditLabelModal = () => {
        setEditLabelFlag(true);
        const { customerLabelList = [], wechatLabelList = [] } = patientTagData;
        let newCustomerLabelIds = customerLabelList.map(item => item.labelId) || [];
        let newWechatLabelIds = wechatLabelList.map(item => item.wechatId) || [];
        setCustomerLableIds(newCustomerLabelIds);
        setWechatLableIds(newWechatLabelIds);
    };

    const renderHeader = () => {
        const { name, avatarUrl, wechatHeadImg, sex = {}, mobile, memberFlag, debtFla, customerLevel } = patientInfoData;
        const { itemCode } = sex;
        return (
            <React.Fragment>
                {pageType === 'group' && (
                    <div style={{background: '#fff', padding: '0.05rem'}} onClick={() => setPageStep('listStep')}>{`< 返回列表`}</div>
                )}
                <div className="base-info-content">
                    <div className="header-img">
                        <img className="header" src={avatarUrl || wechatHeadImg || headerImgDefault} alt="" />
                        <img className="sex" src={itemCode === 'SEX_F' ? femaleIcon : maleIcon} alt="" />
                    </div>
                    <div className="base-info">
                        <div className="name-content">
                            <span className="name">{name}</span>
                            {memberFlag === 'Y' && <img src={huiIcon} alt="" />}
                            {debtFla === 'Y' && <img src={qianIcon} alt="" />}
                            {customerLevel === 'star' ? <img src={starIcon} alt="" /> : customerLevel === 'sun' ? <img src={sunIcon} alt="" /> : customerLevel === 'moon' ? <img src={moonIcon} alt="" /> : ''}
                        </div>
                        <div className="phone-content">
                            <span className="phone">{mobile}</span>
                        </div>
                        {renderTag && (
                            <div className="tags-content" ref={tagBox}>
                                <div className="tags" ref={tagChild} style={showMoreBtn ? { maxWidth: 'calc(100% - 0.5rem)' } : {}}>
                                    {patientTags.map(item => {
                                        return <div className="item-tag" key={item.id}>{item.labelName}</div>;
                                    })}
                                </div>
                                {showMoreBtn && <div className="show-more-btn" style={showTagWidth ? {left: showTagWidth + 'px', right: 'unset'} : {}} onClick={() => setMoreTagModalVisible(true)}>+{patientTags.length - showTagNum}</div>}
                                <div className="add-tag-btn" onClick={() => handleShowEditLabelModal()}>
                                    <img src={addTagBtn} alt="" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const renderMoreTagDrawer = () => {
        return (
            <div className="edit-label-drawer-content">
                <div className="title">
                    标签
                </div>
                <img className="close-label-modal-btn" src={closeLabelModalBtn} alt="" onClick={() => setMoreTagModalVisible(false)} />
                <div className="label-box">
                    <div className="tags-box">
                        {patientTags.map(item => {
                            return <div className="item-tag" key={item.id}>{item.labelName}</div>;
                        })}
                    </div>
                </div>
            </div>
        );
    };

    // 操作标签
    const handleClickTag = (type, id) => {
        if (type === 'WECHAT') {
            if (wechatLableIds.includes(id)) {
                let newArr = wechatLableIds.filter(item => item !== id);
                setWechatLableIds(newArr);
            } else {
                setWechatLableIds([...wechatLableIds, id]);
            }
        }
        if (type === 'SYSTEM') {
            if (customerLableIds.includes(id)) {
                let newArr = customerLableIds.filter(item => item !== id);
                setCustomerLableIds(newArr);
            } else {
                setCustomerLableIds([...customerLableIds, id]);
            }
        }
    };

    // 保存标签
    const savePatientTag = () => {
        setCanSaveTag(false);
        if (saveRef.current) {
            clearTimeout(saveRef.current);
            saveRef.current = null;
        }
        saveRef.current = setTimeout(() => {
            setRenderTag(false);
            fetchData('/his-war/customerLabel/editTagC', {
                body: JSON.stringify({ id: sessionStorage.patinenterId, tagIdList: wechatLableIds, labelList: customerLableIds })
            }).then((res) => {
                if (!!res) {
                    getPatientTagData();
                    setEditLabelFlag(false);
                    setCanSaveTag(true);
                    message.success('保存标签成功');
                } else {
                    message.info('保存标签出错了');
                }
            });
        }, 800);
    };

    const renderEditLabelDrawer = () => {
        const { labelList = [], wechatTagList = [] } = patientTagData;
        return (
            <div className="edit-label-drawer-content">
                <div className="title">
                    添加标签
                </div>
                <img className="close-label-modal-btn" src={closeLabelModalBtn} alt="" onClick={() => setEditLabelFlag(false)} />
                <div className="label-box">
                    <Accordion defaultActiveKey={['SYSTEM', 'WECHAT']}>
                        <Panel header={<span className="tag-type-title">企微标签</span>} key="WECHAT">
                            {wechatTagList.length ? wechatTagList.map(item => {
                                return <div className={`item-tag-edit ${wechatLableIds.includes(item.wechatId) ? 'active' : ''}`} onClick={() => handleClickTag('WECHAT', item.wechatId)}>{item.labelName}</div>;
                            }) : null}
                        </Panel>
                        <Panel header={<span className="tag-type-title">系统标签</span>} key="SYSTEM">
                            {labelList.length ? labelList.map(item => {
                                return <div className={`item-tag-edit ${customerLableIds.includes(item.id) ? 'active' : ''}`} onClick={() => handleClickTag('SYSTEM', item.id)}>{item.labelName}</div>;
                            }) : null}
                        </Panel>
                    </Accordion>
                </div>
                <div className="save-btn" onClick={canSaveTag ? () => savePatientTag() : () => {}}>
                    保存
                </div>
            </div>
        );
    };

    const renderContent = () => {
        let currentPath = location.pathname;
        let redirect = '/patientInfomation/patientDetails' + window.location.search;
        const isOrderPage = currentPath.includes('/patientInfomation/order');
        return (
            <div className="page-content">
                <div className="nav-content" style={{ marginBottom: isOrderPage ? '0' : '0.08rem' }}>
                    {NAV_CONFIG.map(item => {
                        const { title, icon, activeIcon, link } = item;
                        let newLink = `${link}?appId=${localStorage.appId}&agentid=${localStorage.agentid}`;
                        return (
                            <div className="item-nav" onClick={() => history.push(newLink)}>
                                <img src={currentPath.includes(link) ? icon : activeIcon} alt="" />
                                <span className="nav-text" style={currentPath.includes(link) ? { color: '#397CFF' } : {}}>{title}</span>
                            </div>
                        );
                    })}
                </div>
                <div className="content">
                    <Switch>
                        <Route exact path="/patientInfomation" render={() => <Redirect to={redirect} />} />
                        <Route path="/patientInfomation/patientDetails" component={PatientDetails} />
                        <Route path="/patientInfomation/seeDoctorRecord" component={SeeDoctorRecord} />
                        <Route exact path="/patientInfomation/consultationRecord" component={ConsultingRecord} />
                        <Route exact path="/patientInfomation/order" component={OrderList} />
                        <Route exact path="/patientInfomation/order/detail/:id" component={OrderDetail} />
                        <Route exact path="/patientInfomation/returnVisit/addReturnVisit" component={AddReturnVisit} />
                        <Route exact path="/patientInfomation/returnVisit/temporaryReturnVisit/:type" component={AddTemporaryReturnVisit} />
                        <Route path="/patientInfomation/returnVisit" component={ReturnVisit} />
                    </Switch>
                </div>
                {/* 标签弹窗 */}
                {editLabelFlag && (
                    <CommonDrawer
                        content={renderEditLabelDrawer()}
                        onOpenChange={setEditLabelFlag}
                    />
                )}
                {moreTagModalVisible && (
                    <CommonDrawer
                        content={renderMoreTagDrawer()}
                        onOpenChange={setMoreTagModalVisible}
                    />
                )}
            </div>
        );
    };

    // 列表 页面start
    const getPatientListData = (chatId) => {
        fetchData(`/his-war/weChat/cp/queryWechatUserList?chatId=${chatId}`).then((res) => {
            if (res && res.data) {
                setLoading(false);
                setPatientListData(res.data);
                setSearchPatientListData(res.data);
            } else {
                message.info('出错了：' + res.message);
            }
        });
    };

    const handleChangeSearch = value => {
        setSearchTxt(value);
    };

    const handleSearchData = val => {
        const currentListData = patientListData.filter(item => {
            const { name = '', wechatNickName = '' } = item;
            return !!(name + wechatNickName).includes(val);
        });
        setSearchPatientListData(currentListData);
    };

    const handleSearchCancel = () => {
        setSearchTxt('');
        setSearchPatientListData(patientListData);
    };

    const handleJumpInfo = (item) => {
        if (!item.customerId) {
            message.info('非系统患者');
            return;
        }
        setPageStep('infoStep');
        sessionStorage.patinenterId = item.customerId;
        getPatientInfoData();
    };

    const renderPatientList = () => {
        return (
            <React.Fragment>
                <SearchBar value={searchTxt} onChange={handleChangeSearch} placeholder="请输入患者昵称" onSubmit={value => handleSearchData(value)} onCancel={() => handleSearchCancel()}/>
                <div className='patient-list'>
                    {searchPatientListData.length ? searchPatientListData.map(item => {
                        const { name, wechatNickName, secPhone, headImg, customerId } = item;
                        return (
                            <div className='patient-item-content' onClick={() => handleJumpInfo(item)}>
                                <div className='header-img'>
                                    <img src={headImg || headerImgDefault} alt="患者头像" />
                                </div>
                                <div className='message-info'>
                                    <div className='name-text'>{!customerId && !wechatNickName ? '非系统内客户' : `${wechatNickName}(${name})`}</div>
                                    <div className='phone-text'>{secPhone || '--'}</div>
                                </div>
                            </div>
                        );
                    }) : null}
                </div>
            </React.Fragment>
        );
    };
    // 列表 页面end

    return (
        <div className="patientInfo-page">
            {loading && <Spin spinning={true} className="loading-tip" />}
            {pageStep === 'infoStep' && (
                <React.Fragment>
                    {pageError && <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img style={{width: '60%', marginTop: '0.5rem'}} src={pageErrorImg} alt="" /></div>}
                    {!loading && !pageError && renderHeader()}
                    {!loading && !pageError && renderContent()}
                </React.Fragment>
            )}
            {pageStep === 'listStep' && (
                <React.Fragment>
                    {!loading && renderPatientList()}
                </React.Fragment>
            )}
        </div>
    );
};


export default PatientInformation;