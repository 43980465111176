import React from 'react';
import './basicInfomation.less';
import { fromJS } from 'immutable';
import { useSelector, shallowEqual } from 'react-redux';

// 患者信息-全身检查
const GeneralCheckUp = props => {
    const { patientInfo } = props;
    const customer = patientInfo.get('customer') || fromJS({});
    const bodyCheck = patientInfo.get('bodyCheck') || fromJS({});
    const hisCodes = useSelector(state => state.patientInfo, shallowEqual).getIn(['dictMap', 'MANTIS_HAST_HIS_CODE']) || fromJS([]);
    const allergenCodes = useSelector(state => state.patientInfo, shallowEqual).getIn(['dictMap', 'MANTIS_ALLERGEN_CODE']) || fromJS([]);
    const medicationCodes = useSelector(state => state.patientInfo, shallowEqual).getIn(['dictMap', 'MANTIS_MEDICATION_HIS_CODE']) || fromJS([]);
    const toolCodes = useSelector(state => state.patientInfo, shallowEqual).getIn(['dictMap', 'MANTIS_BRUSH_TEETH_CODE']) || fromJS([]);
    const teethHistoryCodes = useSelector(state => state.patientInfo, shallowEqual).getIn(['dictMap', 'MANTIS_TOOTH_HISTORY_CODE']) || fromJS([]);

    // 匹配value值
    const matchValueFun = (dict = fromJS([]), keys = fromJS([])) => {
        const values = dict.filter(item => keys.includes(item.get('itemCode') + '')).map(item => item.get('itemValue'));
        return values.size ? values.toJS().join('、') : '';
    };

    // 病史情况
    const renderMedicalHistoryInfo = () => {
        return <div className='basic-info'>
            <div className="info-title">病史信息</div>
            <div className="info-item">
                <div className="info-label">即往史</div>
                <div className="info-value">{matchValueFun(hisCodes, bodyCheck.get('pastHis'))}</div>
            </div>
            <div className="info-item">
                <div className="info-label">过敏史</div>
                <div className="info-value">{matchValueFun(allergenCodes, bodyCheck.get('allergen'))}</div>
            </div>
            <div className="info-item">
                <div className="info-label">用药史</div>
                <div className="info-value">{matchValueFun(medicationCodes, bodyCheck.get('medicationHis'))}</div>
            </div>
        </div>;
    };

    // 怀孕情况
    const renderPregnancyInfo = () => {
        return customer.getIn(['sex', 'itemCode']) === 'SEX_F' && <div className='basic-info'>
            <div className="info-title">怀孕情况</div>
            <div className="info-item">
                <div className="info-label">怀孕</div>
                <div className="info-value">{bodyCheck.get('pregnancy') === 'Y' ? '是' : '否'}</div>
            </div>
            <div className="info-item">
                <div className="info-label">孕龄</div>
                <div className="info-value">
                    {
                        !!bodyCheck.get('age') ||
                        bodyCheck.get('age') === 0
                            ? bodyCheck.get('age') + '周'
                            : '-'
                    }
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">哺乳</div>
                <div className="info-value">
                    {
                        bodyCheck.get('breastFeed')
                            ? (bodyCheck.get('breastFeed') + '' === 'Y' ? '是' : '否')
                            : ''
                    }
                </div>
            </div>
        </div>;
    };

    // 个人习惯
    const renderPersonageHabit = () => {
        return <div className='basic-info'>
            <div className="info-title">个人习惯</div>
            <div className="info-item">
                <div className="info-label">刷牙</div>
                <div className="info-value">
                    {
                        !!bodyCheck.get('brushNum') ||
                        bodyCheck.get('brushNum') === 0
                            ? bodyCheck.get('brushNum') + '次/天、'
                            : ''
                    }
                    {
                        !!bodyCheck.get('minuteTime') ||
                        bodyCheck.get('minuteTime') === 0
                            ? bodyCheck.get('minuteTime') + '分钟/次'
                            : ''
                    }
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">吸烟</div>
                <div className="info-value">
                    {
                        bodyCheck.get('smokeNum') ||
                        bodyCheck.get('smokeNum') === 0
                            ? bodyCheck.get('smokeNum') + '次/天'
                            : ''
                    }
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">清洁工具</div>
                <div className="info-value">
                    {
                        bodyCheck.get('tool')
                            ? matchValueFun(toolCodes, bodyCheck.get('tool'))
                            : ''
                    }
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">口腔病史</div>
                <div className="info-value">
                    {
                        bodyCheck.get('history')
                            ? matchValueFun(teethHistoryCodes, bodyCheck.get('history'))
                            : ''
                    }
                </div>
            </div>
        </div>;
    };

    return <div className="basic-information-box">
        {renderMedicalHistoryInfo()}
        {renderPregnancyInfo()}
        {renderPersonageHabit()}
    </div>;
};


export default GeneralCheckUp;