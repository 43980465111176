import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { fromJS } from "immutable";
import {message, Spin} from "antd";
import { Icon } from 'antd-mobile';
import moment from "moment";
import { fetchData } from '../../middleware/fetchData';
import { numberFormat } from '../../utils/util';
import './orderList.less';
import OrderInitSum from './OrderInitSum';
import noDataImg from "../../image/noData.png";
import {ListView} from "antd-mobile";

// 患者信息-收费
const OrderList = props => {
    const [status, setStatus] = useState('N'); //付款状态
    const [initSum, setInitSum] = useState({}); //头部统计金额
    const [unPayOrderList, setUnPayOrderList] = useState(fromJS([])); // 未付款订单
    const [payOrderList, setPayOrderList] = useState(fromJS([])); // 已收款订单
    const [loading, setLoading] = useState(true);
    const [upLoading, setUpLoading] = useState(false); // 上拉加载loading
    const [pagination, setPagination] = useState(fromJS({
        current: 1,
        total: 0,
        pageSize: 10
    }));
    const scrollStyle = {height: '100%', overflow: 'auto'};
    const dataSource = new ListView.DataSource({
        rowHasChanged: (row1, row2) => row1 !== row2
    });

    useEffect(() => {
        if(!loading && !upLoading) return;
        if(status === 'N'){
            queryUnPayOrder();
        } else {
            queryPayOrder();
        }
    }, [status, loading, upLoading]);

    // 查询未付款订单
    const queryUnPayOrder = () => {
        fetchData('/his-war/pay/unPayorder', {
            body: JSON.stringify({
                customerId: sessionStorage.patinenterId
            })
        }).then(res => {
            setLoading(false);
            if(!res){
                return;
            }
            if(res.flag === 1 && res.data){
                setInitSum(res.data.initSum || {});
                setUnPayOrderList(fromJS(res.data.unPayorderList || []));
            } else {
                setInitSum({});
                setUnPayOrderList(fromJS([]));
                message.info(res.message);
            }
        });
    };

    // 查询已付款订单
    const queryPayOrder = () => {
        fetchData('/his-war/pay/payorder', {
            body: JSON.stringify({
                condition: {customerId: sessionStorage.patinenterId},
                pageNum: pagination.get('current'),
                pageSize: pagination.get('pageSize')
            })
        }).then(res => {
            setLoading(false);
            setUpLoading(false);
            if(!res){
                return;
            }
            if(res.flag === 1 && res.data){
                setInitSum(res.data.initSum || {});
                const pageInfo = res.data.payorderList ? res.data.payorderList.pageInfo || {} : {};
                const payOrderListNew = payOrderList.concat(fromJS(pageInfo.list || []));
                setPagination(pagination.set('total', pageInfo.total || 0));
                setPayOrderList(payOrderListNew);
            } else {
                setInitSum({});
                setPayOrderList(fromJS(payOrderList));
                message.info(res.message);
            }
        });
    };

    // 上拉加载更多
    const onEndReached = () => {
        if(upLoading) return;
        if(status === 'N') return;
        console.log('上拉加载更多');
        if(pagination.get('total') <= payOrderList.size){
            console.log('已加载完全部数据～');
            return;
        }
        setPagination(pagination.set('current', pagination.get('current') + 1));
        setUpLoading(true);
    };

    // 切换付款状态
    const changeStatus = (value) => {
        setStatus(value);
        setLoading(true);
        setPagination(fromJS({
            current: 1,
            total: 0,
            pageSize: 10
        }));
        setPayOrderList(fromJS([]));
    };

    const renderList = () => {
        const dataList = status === 'N' ? unPayOrderList : payOrderList;
        return (
            dataList.size ? (
                dataList.map(item => {
                    return (
                        <div key={item.get('id')} className='item'>
                            <div className='date'>
                                {item.get('feeDate') ? moment(item.get('feeDate')).format('YYYY-MM-DD HH:mm:ss') : ''}
                                {status === 'Y' && <span className={`status_${item.getIn(['status', 'itemCode'])}`}>{item.getIn(['status', 'itemValue'])}</span>}
                            </div>
                            <p className='label'><span>订单编号</span>{item.get('id')}</p>
                            <p className='label'><span>医生</span>{item.get('doctorName')}</p>
                            <p className='label'><span>本次费用</span>¥{numberFormat(item.get('totalPrice'))}</p>
                            <p className='label'><span>本次折扣金额</span>¥{numberFormat(item.get('totalDiscount'))}</p>
                            <p className='label'><span>专家费用</span>¥{numberFormat(item.get('expertFee'))}</p>
                            {status === 'Y' && <p className='label'><span>实收</span>¥{numberFormat(item.get('paidAmount'))}</p>}
                            {!!(status === 'Y' && item.get('oweAmount')) && <p className='label'><span>欠款</span><span style={{color: '#FF5344'}}>¥{numberFormat(item.get('oweAmount'))}</span></p>}
                            <p className='label'><span>本次应收</span>¥{numberFormat(item.get('totalPrice'))}</p>
                            {status === 'Y' && <p className='label'><span>积分</span>{item.get('integral')}</p>}
                            {status === 'Y' && (
                                <div className='btn'><button><Link to={{pathname: `/patientInfomation/order/detail/${item.get('id')}`, state: {initSum}}}>详情</Link></button></div>
                            )}
                        </div>
                    );
                })
            ) : (
                <img className='empty-img' src={noDataImg} alt="" />
            )
        );
    };

    return (
        <div className='order-list-wrap'>
            <ListView
                onEndReachedThreshold={20}
                dataSource={dataSource}
                renderRow={() => {}}
                style={scrollStyle}
                onEndReached={onEndReached}
            >
                <Spin spinning={loading}>
                    <OrderInitSum initSum={initSum} />
                    <p className='divider'></p>
                    <div className='order-status'>
                        <button onClick={() => changeStatus('N')} className={status === 'N' ? 'act' : ''}>未付款</button>
                        <button onClick={() => changeStatus('Y')} className={status === 'Y' ? 'act' : ''}>已支付</button>
                    </div>
                    <div className='order-list'>
                        {renderList()}
                    </div>
                    {upLoading && <p className='loading'>Loading <Icon type='loading'/></p>}
                </Spin>
            </ListView>
        </div>
    );
};

export default OrderList;