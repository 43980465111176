import React, { Fragment, useEffect, useState } from 'react';
import { Button, Spin, message } from 'antd';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { List, Picker, TextareaItem } from 'antd-mobile';
import { getRouterUrlState } from '../../utils/util';
import { queryCustomerFollowUpById, finishFollowUp, changeResultDetailLoading } from '../../actions/returnVisitAction';
import './addTemporaryReturnVisit.less';
import { fromJS } from 'immutable';

const AddTemporaryReturnVisit = props => {
    const { history } = props;
    const dispatch = useDispatch();
    const visitState = useSelector(state => state.returnVisit, shallowEqual);
    const hisMainState = useSelector(state => state.patientInfo, shallowEqual);
    const resultList = hisMainState.get('resultList') || fromJS([]);
    const userList = hisMainState.get('userList') || fromJS([]);
    const organizations = hisMainState.get('organizations') || fromJS([]);
    const typeCodes = hisMainState.getIn(['dictMap', 'HIS_FOLLOW_UP_TYPE']) || fromJS([]);
    const satisfactionScore = hisMainState.getIn(['dictMap', 'MANTIS_SATISFACTION_SCORE']) || fromJS([]);
    const visitResultContent = visitState.get('visitResultContent') || fromJS({});
    const visitResultContentLoading = visitState.get('visitResultContentLoading');
    const [temporaryVisitValue, setTemporaryVisitValue] = useState({});
    const [typeResultList, setTypeResultList] = useState(fromJS([]));
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);

    const type = window.location.pathname.split('/').pop();
    const customerFollowUpId = getRouterUrlState(window.location.search).customerFollowUpId || '';

    useEffect(() => {
        if (visitResultContentLoading && type === 'edit') {
            dispatch(queryCustomerFollowUpById(customerFollowUpId));
        }
    }, [visitResultContentLoading]);

    useEffect(() => {
        return (() => {
            dispatch(changeResultDetailLoading(true));
        });
    }, [])

    // 取消
    const goback = () => {
        history.push({
            pathname: '/patientInfomation/returnVisit'
        });
    };

    // 保存
    const handleSave = (addType) => {
        const { content, result, resultId, satisfactionScore, typeCode } = temporaryVisitValue;
        let params = {};
        if (type === 'add' && !typeCode) {
            message.error('回访类型不能为空！', 1);
            return;
        }
        if (!result) {
            message.error('回访结果不能为空！', 1);
            return;
        }
        if (type === 'edit') {
            params = { satisfactionScore, result, customerFollowUpId };
        } else {
            params = { thisTypeCode: typeCode, ...temporaryVisitValue };
        }
        setSaveBtnLoading(true);
        dispatch(finishFollowUp(params, () => {
            setSaveBtnLoading(false);
            if (addType === 'addNew') {
                history.push({
                    pathname: '/patientInfomation/returnVisit/addReturnVisit'
                });
                return;
            };
            history.push({
                pathname: '/patientInfomation/returnVisit'
            });
        }));
    };

    // 选择回访结果模板，填入到回访结果内容当中
    const changeResult = (value) => {
        let result = '';
        if (!!value) {
            let temp = resultList.find((opt) => opt.get('id') + '' === value);
            result = temp.get('content') + '';
        }
        setTemporaryVisitValue({ ...temporaryVisitValue, resultId: value, result: result });
    };

    let visitType = typeCodes.find((opt) => opt.get('itemCode') + '' === visitResultContent.get('typeCode') + '');
    let user = userList.find((opt) => opt.get('userId') + '' === visitResultContent.get('ownerId') + '');
    let org = organizations.find((opt) => opt.get('id') + '' === visitResultContent.get('ownerOrgId') + '');

    useEffect(() => {
        if (temporaryVisitValue.typeCode) {
            let newTypeResultList = resultList.filter(
                (value) => value.getIn(['typeCode', 'itemCode']) === temporaryVisitValue.typeCode
            );
            setTypeResultList(newTypeResultList);
        }
    }, [temporaryVisitValue.typeCode]);

    useEffect(() => {
        if (visitResultContent.get('typeCode') && resultList.size) {
            let newTypeResultList = resultList.filter(
                (value) => value.getIn(['typeCode', 'itemCode']) === visitResultContent.get('typeCode')
            );
            setTypeResultList(newTypeResultList);
        }
    }, [visitResultContent.get('typeCode'), resultList]);


    return (
        <div className="addTemporaryReturnVisit">
            <div className="title">
                <span>{type === 'add' ? '新建临时回访计划' : '填写回访结果'}</span>
                <span className="cancel" onClick={goback}>取消</span>
            </div>
            <Spin spinning={type === 'edit' ? visitResultContentLoading : false}>
                <List className="addList">
                    {type === 'edit' && <Fragment>
                        <List.Item>
                            <span className="visitKey">回访类型</span>
                            <span className="visitValue">{visitType && visitType.get('itemValue')}</span>
                        </List.Item>
                        <List.Item>
                            <span className="visitKey">回访机构</span>
                            <span className="visitValue">{org && org.get('clinicName')}</span>
                        </List.Item>
                        <List.Item>
                            <span className="visitKey">回访人</span>
                            <span className="visitValue">{user && user.get('name')}</span>
                        </List.Item>
                        <List.Item>
                            <span className="visitKey">计划回访内容</span>
                            <span className="visitValue">{visitResultContent.get('content') || '-'}</span>
                        </List.Item>
                    </Fragment>}
                    {type === 'add' && <Fragment>
                        <Picker value={[temporaryVisitValue.typeCode]} onChange={value => setTemporaryVisitValue({ ...temporaryVisitValue, typeCode: value[0] })} data={typeCodes.toJS().map(item => ({ value: item.itemCode + '', label: item.itemValue + '' }))} cols={1} className="forss" extra="请选择回访类型" >
                            <List.Item arrow="horizontal"><span className="visitKey must">回访类型</span></List.Item>
                        </Picker>
                        <TextareaItem
                            value={temporaryVisitValue.content}
                            onChange={value => setTemporaryVisitValue({ ...temporaryVisitValue, content: value })}
                            title={<span className="visitKey">计划回访内容</span>}
                            placeholder="请输入计划回访内容"
                            data-seed="logId"
                            autoHeight
                        ></TextareaItem>
                    </Fragment>}
                    <Picker value={[temporaryVisitValue.resultId]} onChange={value => changeResult(value[0])} data={typeResultList.toJS().map(item => ({ value: item.id + '', label: item.content + '' }))} cols={1} className="forss" extra="请选择回访结果模板" >
                        <List.Item arrow="horizontal"><span className="visitKey">回访结果模板</span></List.Item>
                    </Picker>
                    <TextareaItem
                        value={temporaryVisitValue.result}
                        onChange={value => setTemporaryVisitValue({ ...temporaryVisitValue, result: value })}
                        title={<span className="visitKey must">回访结果</span>}
                        placeholder="请输入回访结果"
                        data-seed="logId"
                        autoHeight
                    ></TextareaItem>
                    <Picker value={[temporaryVisitValue.satisfactionScore]} onChange={value => setTemporaryVisitValue({ ...temporaryVisitValue, satisfactionScore: value[0] })} data={satisfactionScore.toJS().map(item => ({ value: item.itemCode + '', label: item.itemValue + '' }))} cols={1} className="forss" extra="请选择满意度评分" >
                        <List.Item arrow="horizontal"><span className="visitKey">满意度评分</span></List.Item>
                    </Picker>
                </List>
            </Spin>
            <div className="bottom-button">
                <Button
                    onClick={handleSave}
                    shape="round"
                    loading={saveBtnLoading}
                    className="info-button save"
                >保存</Button>
                <Button
                    shape="round"
                    loading={saveBtnLoading}
                    onClick={() => handleSave('addNew')}
                    className="info-button"
                    type="primary"
                >保存并新建回访</Button>
            </div>
        </div>
    )
}
export default AddTemporaryReturnVisit;
