import { message } from "antd";
import { fetchData } from "../../middleware/fetchData";

// 获取验证码
export const sendVerifyCode = (phone) => dispatch => {
    fetchData(`/his-war/weChat/cp/sendVerifyCode`, {
        body: JSON.stringify({ phone })
    }).then(res => {
        if (!res) return;
        const { flag, message: msg } = res;
        if (typeof flag !== 'undefined' && flag === 1) {
        } else {
            message.error(`获取验证码失败，失败原因：${msg}`);
            return;
        }
    });
};

// 校验验证码
export const checkVerifyCode = (phone, verifyCode, callback) => dispatch => {
    fetchData(`/his-war/weChat/cp/checkVerifyCode`, {
        body: JSON.stringify({ phone, verifyCode })
    }).then(res => {
        if (!res) return;
        const { flag, message: msg, data } = res;
        if (!!data) {
            callback && callback();
        } else {
            message.error('请输入正确的验证码!');
            return;
        }
    });
};

// 根据手机号和微信Id查询患者
export const queryCustomer = (params, callback) => dispatch => {
    fetchData(`/his-war/weChat/cp/queryCustomer`, {
        body: JSON.stringify(params)
    }).then(res => {
        if (!res) return;
        const { flag, message: msg, data } = res;
        if (typeof flag !== 'undefined' && flag === 1) {
            callback && callback(data || []);
        } else {
            callback && callback(flag === 201 ? 'fail' : '');
            flag !== 201 && message.error(`查询患者信息失败，失败原因：${msg}`);
            return;
        }
    });

};

// 绑定微信unionId与手机号
export const bindCustomer = (params, callback) => dispatch => {
    fetchData(`/his-war/weChat/cp/bindCustomer`, {
        body: JSON.stringify(params)
    }).then(res => {
        if (!res) return;
        const { flag, message: msg, data } = res;
        if (typeof flag !== 'undefined' && flag === 1) {
            callback && callback(flag);
        } else {
            if (flag === 0) {
                callback && callback(flag);
            } else {
                message.error(`查询患者信息失败，失败原因：${msg}`);
            }
            return;
        }
    });
};
