import React, { useState, useEffect } from 'react';
import ClipboardJS from 'clipboard';
import moment from 'moment';
import { message } from 'antd';
import { fromJS } from 'immutable';
import { getFormatDate } from '../../utils/dateUtils';
import copyIcon from '../../image/copyIcon.svg';
import './basicInfomation.less';

// 患者信息-基本信息
const BasicInformation = props => {
    const { patientInfo } = props;
    const customer = patientInfo.get('customer') || fromJS({});
    const [copyInfo, setCopyInfo] = useState(''); // 复制的信息

    useEffect(() => {
        //实例化 ClipboardJS对象;
        let copyBtn = new ClipboardJS('.copyBtn');
        copyBtn.on('success', function (e) {
            // 复制成功
            message.destroy();
            message.success('复制成功');
            e.clearSelection();
        });
        copyBtn.on('error', function (e) {
            //复制失败；
            message.destroy();
            message.info('复制失败');
            console.log(e.action);
        });
    }, []);

    // 复制信息
    const handleCopy = value => {
        setCopyInfo(value);
        setTimeout(() => {
            document.getElementById('copyBtn').click();
        }, 100);
    };

    // 添加企微信息
    const renderAddWechat = list => {
        let newList = list.map((item) => {
            return (item.get('nickname') || '') + '(' + (item.get('jobName') || '') + ')';
        });
        return <span>
            {newList.join('、')}
        </span>;
    };

    // 基础信息
    const renderBasicInfo = () => {
        return <div className='basic-info'>
            <div className="info-title">基础信息</div>
            <div className="info-item">
                <div className="info-label">姓名</div>
                <div className="info-value">{customer.get('name')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">微信昵称</div>
                <div className="info-value">{customer.get('wechatNickname')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">性别</div>
                <div className="info-value">{customer.getIn(['sex', 'itemValue'])}</div>
            </div>
            <div className="info-item">
                <div className="info-label">生日</div>
                <div className="info-value">{customer.get('birthday') ? getFormatDate(moment(customer.get('birthday')), 'YYYY-MM-DD') : ''}</div>
            </div>
            <div className="info-item">
                <div className="info-label">年龄</div>
                <div className="info-value">{!!customer.get('birthday') || customer.get('birthday') === 0
                    ? moment().diff(moment(customer.get('birthday')), 'years')
                    : null}</div>
            </div>
            <div className="info-item">
                <div className="info-label">手机</div>
                <div className="info-value">
                    {!!customer.get('mobile')
                        ? customer.get('mobile') +
                        (customer.getIn(['mobileType', 'itemValue'])
                            ? `(${customer.getIn([
                                'mobileType',
                                'itemValue'
                            ])})`
                            : '')
                        : '-'}
                    {
                        !!customer.get('mobile') && <span className='copy-icon' onClick={() => handleCopy(customer.get('mobile'))}>
                            <img src={copyIcon} alt=""/>
                        </span>
                    }
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">住址</div>
                <div className="info-value">{customer.get('address')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">添加企微</div>
                <div className="info-value">{renderAddWechat(customer.get('wechatCpBeanList') || fromJS([]))}</div>
            </div>
        </div>;
    };

    // 就诊信息
    const renderContactInfo = () => {
        return <div className='basic-info'>
            <div className="info-title">就诊信息</div>
            <div className="info-item">
                <div className="info-label">患者类别</div>
                <div className="info-value">{customer.getIn(['typeCode', 'itemValue'])}</div>
            </div>
            <div className="info-item">
                <div className="info-label">病例号</div>
                <div className="info-value">{customer.get('recordId')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">咨询项目</div>
                <div className="info-value">{customer.getIn(['mspProduct', 'name'])}</div>
            </div>
            <div className="info-item">
                <div className="info-label">一级来源</div>
                <div className="info-value">{customer.getIn(['sourceCode', 'itemValue'])}</div>
            </div>
            <div className="info-item">
                <div className="info-label">
                    {['员工推荐', '朋友介绍'].includes(
                        customer.getIn(['sourceCode', 'itemCode'])
                    ) ? '介绍人' : '二级来源'}
                </div>
                <div className="info-value">
                    {['员工推荐', '朋友介绍'].includes(
                        customer.getIn(['sourceCode', 'itemCode'])
                    ) // 一级来源是"员工推荐", "朋友介绍"时
                        ? customer.get('emplName')
                        : customer.getIn(['secondSourceCode', 'itemValue'])}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">初诊项目</div>
                <div className="info-value">
                    {!!customer.getIn(['product', 'name'])
                        ? customer.getIn(['product', 'name'])
                        : ''}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">初诊时间</div>
                <div className="info-value">
                    {customer.get('firstClinicTime')
                        ? getFormatDate(moment(customer.get('firstClinicTime')), 'YYYY-MM-DD')
                        : ''
                    }
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">初诊医生</div>
                <div className="info-value">
                    {!!customer.getIn(['firstDoctor', 'name'])
                        ? customer.getIn(['firstDoctor', 'name'])
                        : ''}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">责任医生</div>
                <div className="info-value">
                    {!!customer.getIn(['dutyDoctor', 'name']) ? (
                        customer.getIn(['dutyDoctor', 'name'])
                    ) : (
                        '-'
                    )}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">专属咨询</div>
                <div className="info-value">
                    {!!customer.getIn(['dutyConsult', 'name']) ? (
                        customer.getIn(['dutyConsult', 'name'])
                    ) : (
                        '-'
                    )}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">创建机构</div>
                <div className="info-value">
                    {!!customer.getIn(['createOrg', 'name'])
                        ? customer.getIn(['createOrg', 'name'])
                        : ''}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">创建人</div>
                <div className="info-value">
                    {!!customer.get('createName') ? customer.get('createName') : ''}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">创建时间</div>
                <div className="info-value">
                    {!!customer.get('createTime')
                        ? moment(customer.get('createTime')).format('YYYY-MM-DD')
                        : '-'}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">成交时间</div>
                <div className="info-value">
                    {!!customer.get('customerTurnoverHis') ? (
                        moment(
                            customer.getIn(['customerTurnoverHis', 'turnoverTime'])
                        ).format('YYYY-MM-DD')
                    ) : (
                        '-'
                    )}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">备注</div>
                <div className="info-value">{customer.get('remark')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">电网咨询</div>
                <div className="info-value">
                    {!!customer.getIn(['networkUser', 'name'])
                        ? customer.getIn(['networkUser', 'name'])
                        : '-'}
                </div>
            </div>
        </div>;
    };

    // 其他信息
    const renderOtherInfo = () => {
        return <div className='basic-info'>
            <div className="info-title">其他信息</div>
            <div className="info-item">
                <div className="info-label">电话</div>
                <div className="info-value">
                    {!!customer.get('telphone') ? (
                        customer.get('telphone')
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="info-item">
                <div className="info-label">邮箱</div>
                <div className="info-value">{customer.get('email')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">身份号码</div>
                <div className="info-value">{customer.get('cardNo')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">国籍</div>
                <div className="info-value">{customer.getIn(['nationalityCode', 'itemValue'])}</div>
            </div>
            <div className="info-item">
                <div className="info-label">职业</div>
                <div className="info-value">{customer.getIn(['professionalCode', 'itemValue'])}</div>
            </div>
            <div className="info-item">
                <div className="info-label">兴趣</div>
                <div className="info-value">{customer.get('interest')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">身份</div>
                <div className="info-value">{customer.get('identity')}</div>
            </div>
        </div>;
    };

    return <div className="basic-information-box">
        {renderBasicInfo()}
        {renderContactInfo()}
        {renderOtherInfo()}
        <div className="copy-box">
            <input type="text" id="copyVal" readOnly value={copyInfo} />
            <button id='copyBtn' className="copyBtn" data-clipboard-target="#copyVal">复制</button>
        </div>
    </div>;
};


export default BasicInformation;