import React, { useState, useEffect, useRef } from 'react';
import { fetchData } from '../../middleware/fetchData';
import { Toast, Modal, List, Picker, TextareaItem, InputItem } from 'antd-mobile';
import { Spin, message } from 'antd';
import './introducerList.less';
import { fromJS } from 'immutable';
import './friendsRelationEditModal.less';
import { shallowEqual, useSelector } from 'react-redux';
import searchIcon from '../../image/searchIcon.svg';
import noDataImg from '../../image/noData.png';

// 患者信息-亲友关系-编辑弹窗
const FriendsRelationEditModal = props => {
    const { editItem, visible, handleCancel, getRelationList } = props;
    const [typeCodes, setTypeCodes] = useState([]);
    const relationshipTypes = useSelector(state => state.patientInfo, shallowEqual).getIn(['dictMap', 'MANTIS_CUSTOMER_RELATIONSHIP_TYPE']) || fromJS([]);
    const [loading, setLoading] = useState(false);
    const [newItem, setNewItem] = useState(fromJS({}));
    const [isShowSearchModal, setIsShowSearchModal] = useState(false);
    const [inputName, setInputName] = useState('');
    const [currentNameList, setCurrentNameList] = useState(fromJS([]));
    const [currentNamePagination, setCurrentNamePagination] = useState({
        pageNum: 1,
        pageSize: 10,
        total: 0
    });
    const searchTimeRef = useRef(0);
    const [searchLoading, setSearchLoading] = useState(false);
    const [currentSelectRelation, setCurrentSelectRelation] = useState(fromJS({})); // 当前选中的关系人

    useEffect(() => {
        if (editItem && editItem.get('id')) {
            let newEditItem = editItem.set('tempTypeCode', editItem.getIn(['typeCode', 'itemCode']));
            setNewItem(newEditItem);
            getPatientInfo(newEditItem.get('targetCustomerId'));
        } else {
            setNewItem(fromJS({}));
            setCurrentSelectRelation(fromJS({}));
        }
    }, [editItem]);

    useEffect(() => {
        if (relationshipTypes.size) {
            let newList = relationshipTypes.map(item =>
                item.set('label', item.get('itemValue')).set('value', item.get('itemCode')));
            setTypeCodes(newList.toJS());
        }
    }, [relationshipTypes]);

    useEffect(() => {
        if (!isShowSearchModal) {
            setInputName(undefined);
            setCurrentNameList(fromJS([]));
        }
    }, [isShowSearchModal]);

    // 搜索输入框变化
    useEffect(() => {
        if (!isShowSearchModal) return false;
        if (searchTimeRef.current) {
            clearTimeout(searchTimeRef.current);
        }
        if (!inputName || !inputName.trim()) return;
        let currentInputName = inputName.trim();
        searchTimeRef.current = setTimeout(() => {
            //全部数字
            if (
                /^((13\d|14\d|15[^4\D]|16\d|17\d|18\d|19\d)\d{8}$|170[^346\D]\d{7}$)|(^(0[0-9]{2,3})([1-9][0-9]{6,7})$|^[1-9][0-9]{6,7}$|^[\+]?\d{1,19}$)/.test(
                    currentInputName
                ) &&
                currentInputName.length <= 4
            ) {
                message.info('根据手机号搜索至少输入5位');
                return false;
            }
            //全部汉字
            if (/^[\u4E00-\u9FA5]$/.test(currentInputName) && currentInputName.length < 4) {
                message.info('根据姓名搜索至少输入2位');
                return false;
            }
            //全部字母
            if (/^[a-zA-Z]+$/.test(currentInputName) && currentInputName.length < 3) {
                message.info('根据字母搜索至少输入3位');
                return false;
            }
            //字母加数字
            let regNumber = /\d+/;
            let regString = /[a-zA-Z]+/;
            if (regNumber.test(currentInputName) && regString.test(currentInputName) && currentInputName.length < 5) {
                message.info('根据字母加数字搜索至少输入5位');
                return false;
            }
            getNameList();
        }, 800);
    }, [isShowSearchModal, inputName]);

    // 查询患者信息
    const getPatientInfo = id => {
        fetchData(`/his-war/customer/detail`, {
            body: JSON.stringify({
                id
            })
        }).then((res) => {
            if (res && res.flag === 1) {
                setCurrentSelectRelation(fromJS(res.data));
            } else {
                message.info('获取患者信息失败' + (res ? ':' + res.message : ''));
            }
        });
    };

    // 保存
    const saveFriendsRelationInfo = () => {
        if (loading) return;
        if (!currentSelectRelation.get('id')) {
            message.info('请选择患者');
            return false;
        }
        if (currentSelectRelation.get('id') + '' === sessionStorage.patinenterId) {
            message.info('亲属不能选择当前患者');
            return false;
        }
        if (!newItem.get('tempTypeCode')) {
            message.info('请选择关系类别');
            return false;
        }
        setLoading(true);
        Toast.loading('');
        let url = '';
        let params = {
            typeCode: newItem.get('tempTypeCode'),
            remark: newItem.get('remark'),
            targetCustomerId: currentSelectRelation.get('id'),
            sourceCustomerId: sessionStorage.patinenterId
        };
        if (editItem.get('id')) {
            url = '/his-war/customer/editRelation';
            params.id = editItem.get('id');
        } else {
            url = '/his-war/customer/addRelation';
        }
        fetchData(url, {
            body: JSON.stringify(params)
        }).then((res) => {
            Toast.hide();
            if (res && res.flag === 1) {
                setLoading(false);
                message.success('保存成功');
                getRelationList();
                handleCancel();
            } else {
                setLoading(false);
                message.info('保存失败' + (res ? ':' + res.message : ''));
            }
        });
    };

    // 改变值
    const handleChangeValue = (key, value) => {
        setNewItem(newItem.set(key, value));
    };

    // 打开名称搜索弹窗
    const handleOpenSearchModal = () => {
        setIsShowSearchModal(true);
    };

    // 关闭名称搜索弹窗
    const handleCloseSearchModal = () => {
        setIsShowSearchModal(false);
    };

    // 查询关系人列表
    const getNameList = (pageNum = 1) => {
        setSearchLoading(true);
        fetchData(`/his-war/customer/searchAll`, {
            body: JSON.stringify({
                pageNum: pageNum,
                pageSize: currentNamePagination.pageSize,
                condition: {
                    condition: inputName
                }
            })
        }).then((res) => {
            if (res && res.data) {
                setSearchLoading(false);
                const pageInfo = res.data.pageInfo || {};
                setCurrentNamePagination({
                    pageNum: pageInfo.pageNum || 0,
                    pageSize: pageInfo.pageSize || 10,
                    total: pageInfo.total || 0,
                });
                let dataList = pageInfo.list || [];
                if (pageInfo.pageNum > 1) {
                    setCurrentNameList(currentNameList.concat(fromJS(dataList || [])));
                } else {
                    setCurrentNameList(fromJS(dataList || []));
                }
            } else {
                setSearchLoading(false);
                message.info('查询失败' + (res ? ':' + res.message : ''));
            }
        });
    };

    // 获取更多
    const handleGetMore = () => {
        getNameList(currentNamePagination.pageNum + 1);
    };

    // 选择关系人
    const handleSelectRelation = item => {
        setCurrentSelectRelation(item);
        setIsShowSearchModal(false);
    };

    // 亲友关系
    const renderFriendsRelationInfo = () => {
        return <div className="bottom-container" key={newItem.get('id') || newItem.get('key')}>
            <Picker value={[newItem.get('tempTypeCode')]} onChange={value => handleChangeValue('tempTypeCode', value[0])} data={typeCodes} cols={1} extra="请选择关系类别" >
                <List.Item arrow="horizontal">
                    <span className="info-item-select">关系类别</span>
                </List.Item>
            </Picker>
            <div className="info-item" onClick={handleOpenSearchModal}>
                <div className="info-label">关系人姓名</div>
                <div className="info-value">{currentSelectRelation.get('id') ? currentSelectRelation.get('name') : newItem.get('name')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">性别</div>
                <div className="info-value">{currentSelectRelation.get('id') ? currentSelectRelation.getIn(['sex', 'itemValue']) : newItem.getIn(['sex', 'itemValue'])}</div>
            </div>
            <div className="info-item">
                <div className="info-label">手机</div>
                <div className="info-value">{(currentSelectRelation.get('telphone') || currentSelectRelation.get('mobile')) ? (currentSelectRelation.get('telphone') || currentSelectRelation.get('mobile')) : (newItem.get('telphone') || newItem.get('mobile'))}</div>
            </div>
            <div className="info-item">
                <div className="info-label">病历号</div>
                <div className="info-value">{currentSelectRelation.get('recordId') ? currentSelectRelation.get('recordId') : newItem.get('recordId')}</div>
            </div>
            <div className="info-item">
                <div className="info-label">会员卡账号</div>
                <div className="info-value">{currentSelectRelation.get('memberNum') ? currentSelectRelation.get('memberNum') : newItem.get('memberNum')}</div>
            </div>
            <div className="info-item no-bottom-border">
                <div className="info-label">备注</div>
            </div>
            <TextareaItem
                value={newItem.get('remark')}
                onChange={value => handleChangeValue('remark', value)}
                title={null}
                placeholder="请输入备注"
                rows={3}
                count={500}
            />
        </div>;
    };

    // 名称搜索弹窗
    const renderSearchModal = () => {
        return <Modal
            className='friends-relation-edit-modal name-search-modal'
            visible
            popup
            closable
            onClose={handleCloseSearchModal}
            animationType="slide-up"
            title={'查找关系人'}
            wrapStyle={{ transform: 'unset' }}
        >
            <InputItem
                value={inputName}
                onChange={setInputName}
                placeholder="请输入姓名/电话/病历号/拼音"
            >
                <img className='before-input-icon' src={searchIcon} alt=""/>
            </InputItem>
            {<Spin spinning={searchLoading}>
                <div className='friends-list-container'>
                    {currentNameList.size ? currentNameList.map(item => {
                        return <div className="friends-box" key={item.get('id')} onClick={() => handleSelectRelation(item)}>
                            <div className="name-phone">{`${item.get('name')}(${item.get('mobile')})`}</div>
                            {item.get('dutyDoctor') && item.getIn(['dutyDoctor', 'name']) ? (
                                <div>
                                    {item.getIn(['dutyDoctor', 'name'])} ({item.getIn(['ownerOrg', 'name'])})
                                </div>
                            ) : null}
                            <div className='number'>{item.get('recordId')}</div>
                        </div>;
                    }) : <div className="no-data-content"><img src={noDataImg} alt="" /></div>}
                </div>
                {currentNamePagination.pageSize * currentNamePagination.pageNum < currentNamePagination.total
                    && <div className='get-more-btn' onClick={handleGetMore}>查看更多</div>}
            </Spin>}
        </Modal>;
    };

    return <React.Fragment>
        <Modal
            className='friends-relation-edit-modal'
            popup
            visible={visible}
            maskClosable={false}
            closable
            onClose={handleCancel}
            animationType="slide-up"
            title={editItem.get('id') ? '编辑亲友关系' : '添加亲友关系'}
            wrapStyle={{ transform: 'unset' }}
        >
            {renderFriendsRelationInfo()}
            <div className="bottom-operation-box">
                <div className="save-btn" onClick={saveFriendsRelationInfo}>保存</div>
            </div>
            {isShowSearchModal && renderSearchModal()}
        </Modal>
    </React.Fragment>;
};


export default FriendsRelationEditModal;