/**
 * 转换日期对象为日期字符串
 * @param date long值
 * @param pattern 格式字符串,例如：yyyy-MM-dd hh:mm:ss
 * @return 符合要求的日期字符串
 */
export function getFormatDate(date, pattern) {
    if (date === undefined) {
        date = new Date();
    }
    if (pattern === undefined) {
        pattern = 'YYYY-MM-DD hh:mm:ss';
    }
    return date.format(pattern);
}